import React, { useState, useEffect, useReducer, useRef } from "react";
import MUIDataTable from "mui-datatables";
import {
  Grid,
  Button,
  List,
  Box,
  SwipeableDrawer, 
  TextField,
  FormControl,
  InputLabel, 
  Typography,
  Select, 
  MenuItem,
    TablePagination, 
   Dialog, DialogContent, DialogContentText, DialogActions, 
  Backdrop,
  CircularProgress, 
} from "@material-ui/core";
 import PageTitle from "../../components/PageTitle";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import { toast } from "react-toastify";
 import AddCircleIcon from "@material-ui/icons/AddCircle";
 import ViewIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip"; 
 import useStyles from "../../themes/style.js";
 import useMediaQuery from '@material-ui/core/useMediaQuery';
 import Notification from "../../components/Notification/Notification";
 
import { campaignTagSchema, campaignTagwithioutSchema  } from "../../components/Yup/Yup";
import { campaignByTag, viewTagCampaign, viewAllTagCampaign, rerunTagCampaign, viewCampaignMessageStatus, removeTagCampaignNumber, editScheduledCampaignTime } from '../../services/campaign.api';
import { viewAllTags } from '../../services/tags.api'; 
import { viewAuthentication, uploadFile, getUserInstance } from '../../services/instance.api';
import {   FcGallery,   FcVideoFile, FcDocument,  } from "react-icons/fc";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import SendIcon from '@material-ui/icons/Send';
import { viewApprovedTemplates, viewTemplate } from '../../services/template.api';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Autocomplete } from "@material-ui/lab"; 
import { useFormik } from 'formik';
 
import "react-toastify/dist/ReactToastify.css";
 

export default function CampaignTag(props) {

 
  const classes = useStyles();
  var moment = require('moment-timezone');


  const mobileQuery = useMediaQuery('(max-width:600px)');  
  const messageRef = useRef();
  const [imageLoad, setImageLoad] = useState(null);
  const [fileData] = React.useState({
    "image": [],
    "audio": [],
    "video": [],
    "document": [],
    "imageUrl": "",
  }); 
   const [campaignData, setCampaignData] = useState([]);
 
  const [tagData, setTagData] = useState([]);

  const [campaignView, setCampaignView] = useState({
    data:{}, 
    table:[], 
   });


   var token = localStorage.getItem("token");
   
  const [page, setPage] = useState(0);
  const [campainPage, setCampainPage] = useState(0);

  const [currerntPage, setCurrerntPage] = useState(1);
  const [campainCurrerntPage, setCampainCurrerntPage] = useState(1);

  const [count, setCount] = useState(0);
  const [campainCount, setCampainCount] = useState(0);

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [dataList, setDataList] = useState("ADD"); 

  const [loader, setLoader] = useState(false);
  const [templateData, setTemplateData] = React.useState([]);
  const [instanceData, setInstanceData] = React.useState([]);

  const [instanceValue, setInstanceValue] = React.useState(null);
  const [Id, setId] = React.useState();
 

  const [templateHeader, setTemplateHeader] = React.useState();

  const user = JSON.parse(localStorage.getItem("user"));


  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress, 
          className: classes.notification,
        },
    );
  } 


  const HeaderElements = () => <>Total : {count}</>;
  const HeaderCampainElements = () => <>Total : {campainCount}</>;

  const handleChangePage = (event, newPage) => {
    setLoader(true);
   

    viewCampaignMessageStatus({page: newPage + 1, id: campaignView?.data?.id, role_code: user?.role_code, token: token}).then((result) => {    
      if (result.data.status === true) {    
 
        setCampaignView({
          ...campaignView, 
          table: result.data.data
        });
       
        setCount(result.data.count);

          }  else{
            setCount(0);
          }
          setPage(newPage);
          setCurrerntPage(newPage + 1);
          setLoader(false);
        }) 
          .catch(function (error) {
           console.log(error);
         })

  };
  

  const handleCampainChangePage = (event, newPage) => {
    setLoader(true);
    

    viewAllTagCampaign({page: newPage + 1, role_code: user?.role_code, token: token}).then((response) => {    
      if (response.data.status === true) { 

       setCampaignData(response.data?.data);
       setCampainCount(response.data?.count);
          
      } else{
        setCampainCount(0);
      }
      setCampainPage(newPage);
      setCampainCurrerntPage(newPage + 1);
      setLoader(false);
    }) 
    .catch(function (error) {
     console.log(error);
   })

  };
  const [resendOpen, setResendOpen] = React.useState(false);
 
  const handleResendOpen = () => {
    setResendOpen(true);
  };

  const handleResendClose = () => {
    setResendOpen(false);
  };
    
  const [instanceId, setInstanceId] = React.useState();

 
  const [removeOpen, setRemoveOpen] = React.useState(false);
  const [removeBulkOpen, setRemoveBulkOpen] = React.useState(false);

  const handleRemoveOpen = () => {
    setRemoveOpen(true);
  };

  const handleRemoveClose = () => {
    setRemoveOpen(false);
  };

  const handleBulkRemoveOpen = () => {
    setRemoveBulkOpen(true);
  };

  const handleBulkRemoveClose = () => {
    setRemoveBulkOpen(false);
  };
 

  var selectId = [];

  const handleRowSelectionChange = (allRowsSelected) => {
    
    const id = allRowsSelected?.map((rowIndex) =>    ( {    
      "campaign_id": campaignView?.table[rowIndex.dataIndex].campaign_id,
      "tag_id": campaignView?.table[rowIndex.dataIndex].tag_id,
      "message_contacts_id": campaignView?.table[rowIndex.dataIndex].message_contacts_id,
      "message_id": campaignView?.table[rowIndex.dataIndex].message_id
    }));
 
    selectId = id;  
   };

   const handleClickDelete = (allRowsSelected) => { 
    
    const id = {   
      "campaign_id": allRowsSelected.campaign_id,
      "group_id": allRowsSelected.group_id,
      "contact_id": allRowsSelected.contact_id, 
      "message_id": allRowsSelected.message_id
    } 
 
    selectId = id;  
    setselectedId([selectId]);
    };

   const [selectedId, setselectedId] = React.useState(selectId);

 


  useEffect(() => {
  viewAuthentication({userId: user?.userId, token: token}).then((response) => {   
       
    if (response.data.status === true) {   
      setInstanceId(JSON.parse(JSON.stringify(response?.data?.data)));  
       
        
    viewApprovedTemplates({instance_id: response?.data?.data?.id, token: token }).then((result) => {    
      if (result.data.status === true) { 

       setTemplateData(result.data.data);
        
      } 
     
      setLoader(false);
    }) 
    .catch(function (error) {
     console.log(error);
   })   

     }  
  }) 
  .catch(function (error) {
   console.log(error);
 })
 // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);


  useEffect(() => {
 
    setLoader(true);
     
    getUserInstance({instance_id: instanceId?.id, token: token }).then((response) => {    
         if (response.data.status === true) { 
   
          setInstanceData(response.data.data);
           
         } 
        
         setLoader(false);
       }) 
       .catch(function (error) {
        console.log(error);
      })    
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

  
  

  useEffect(() => {
    setLoader(true);

   
    const fetchData = async () => {
       
      viewAllTagCampaign({page: 1, role_code: user?.role_code, token: token}).then((response) => {    
         if (response.data.status === true) { 
 
          setCampaignData(response.data?.data);
          setCampainCount(response.data?.count);
             
         } else{
          setCampainCount(0);
        }
        setCampainPage(0);
        setCampainCurrerntPage(1);
         setLoader(false);
       }) 
       .catch(function (error) {
        console.log(error);
      })
 
    };
    fetchData();
       
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reducerValue]);
  

   
  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
       
        viewAllTags({role_code: user?.role_code, token: token}).then((response) => {    
         if (response.data.status === true) { 
           
          setTagData(response.data.data);
          
         } 
        
         setLoader(false);
       }) 
       .catch(function (error) {
        console.log(error);
      })
 
    };
    fetchData();
       
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
  

   const onCampaignAdd = useFormik({
    initialValues: { 
      instance:'',
      campaign_name: '',
      tag_id: '', 
      template_id:'',
      vars:'',
      header: '',  
      headerType:'',
      type: 'Immediate',
      schedule:'',
    },
     validationSchema: templateHeader ==="IMAGE" || templateHeader ==="VIDEO" || templateHeader ==="DOCUMENT"? campaignTagSchema : campaignTagwithioutSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {  
      
      setLoader(true);
     
      campaignByTag({ instance_id: instanceData[onCampaignAdd.values.instance]?.id, campaign_name: values.campaign_name, tag_id: tagData[onCampaignAdd.values.tag_id]?.id, 
        template_id: templateData[values.template_id]?.id, vars:values.vars, message:messageRef.current?.innerText,
        headerType:values.headerType, header:values.header,  type:values.type, schedule:values.schedule!== undefined? (moment(values.schedule).tz('UTC')).format("YYYY-MM-DD HH:mm") :"" , token: token, 
      }).then((response) => {    
 
      if (response.data.status === true) { 
        handleNotificationCall("success", response.data.message);  
        forceUpdate(); 
        resetForm();
        setMessageData({});
        onCampaignAdd.resetForm(); 
        setState({ ...state, right: false }); 
       } else {  
        handleNotificationCall("error", response.data.message);  
       }
       setSubmitting(false); 
       setLoader(false);
     }) 
     .catch(function (error) {
      console.log(error);
    })

    },
  });  

  const onCampaignRescheduled = useFormik({
    initialValues: {  
      schedule:'',
    }, 
     onSubmit: (values, { setSubmitting, resetForm }) => {  
      setLoader(true);
     
      editScheduledCampaignTime({ id: campaignView.data?.id,  schedule:values.schedule!== undefined?  (moment(values.schedule).tz('UTC')).format("YYYY-MM-DD HH:mm") :"" , token: token, 
      }).then((response) => {    
 
      if (response.data.status === true) { 
        handleNotificationCall("success", response.data.message);  
        forceUpdate();   
       } else {  
        handleNotificationCall("error", response.data.message);  
       }
       setSubmitting(false); 
       setLoader(false);
     }) 
     .catch(function (error) {
      console.log(error);
    })

    },
  }); 

  
  function handleSendTemplateImage(event, name){

    var FormData = require("form-data");
    var data = new FormData();
    if(name === "image" && event.target.files[0]?.size < 5000000 && (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/png")){

      //setLoader(true); 
      setImageLoad(true);
      
      data.append("file", event.target.files[0]);
     
      uploadFile(data, {token:  token}).then((response) => {    
      
        if (response.data.status === true) {  
          setImageLoad(false);
          onCampaignAdd.setValues({
            "instance":  onCampaignAdd.values.instance,
            "tag_id": onCampaignAdd.values.tag_id,
            "campaign_name": onCampaignAdd.values.campaign_name,  
            "template_id":  onCampaignAdd.values.template_id,  
            "template": templateData.indexOf(onCampaignAdd.values.template),
            "mobile_number": onCampaignAdd.values.mobile_number, 
            "template_name": onCampaignAdd.values.template_name, 
            "language_code": onCampaignAdd.values.language_code, 
            "header":  response.data?.file_url, 
            "headerType":  onCampaignAdd.values.headerType,  
            "parameters": onCampaignAdd.values.parameters, 
            "schedule":  onCampaignAdd.values.schedule, 
            "type":  onCampaignAdd.values.type, 
           // "footer": onCampaignAdd.values.footer, 
           // "button": onCampaignAdd.values.button, 
          })
        } else {
          setImageLoad(null);
          handleNotificationCall("error", response.data.message);   
        }
    }) 
  } else if(name === "audio" && event.target.files[0]?.size < 16000000 && (event.target.files[0].type === "audio/aac" || event.target.files[0].type === "audio/mp4" || event.target.files[0].type === "audio/mpeg" || event.target.files[0].type === "audio/amr" || event.target.files[0].type === "audio/ogg")){
    //setLoader(true); 
      setImageLoad(true);
     
      data.append("file", event.target.files[0]);
       uploadFile(data, {token:  token}).then((response) => {    
      
        if (response.data.status === true) {  
          setImageLoad(false);
          onCampaignAdd.setValues({
            
            "instance":  onCampaignAdd.values.instance,
            "tag_id": onCampaignAdd.values.tag_id,
            "campaign_name": onCampaignAdd.values.campaign_name,  
            "template_id":  onCampaignAdd.values.template_id, 
            "template": templateData.indexOf(onCampaignAdd.values.template),
            "mobile_number": onCampaignAdd.values.mobile_number, 
            "template_name": onCampaignAdd.values.template_name, 
            "language_code": onCampaignAdd.values.language_code, 
            "header":  response.data?.file_url, 
            "headerType":  onCampaignAdd.values.headerType,  
            "parameters": onCampaignAdd.values.parameters, 
            "schedule":  onCampaignAdd.values.schedule, 
            "type":  onCampaignAdd.values.type, 
          //  "footer": onCampaignAdd.values.footer, 
           // "button": onCampaignAdd.values.button, 
          })
        } else {
          setImageLoad(null);
          handleNotificationCall("error", response.data.message);   
        }
    }) 
    } else if(name === "video" && event.target.files[0]?.size < 16000000 && (event.target.files[0].type === "video/mp4" || event.target.files[0].type === "video/3gp")){
     // setLoader(true); 
      setImageLoad(true);
      
      data.append("file", event.target.files[0]);
       uploadFile(data, {token:  token}).then((response) => {    
      
        if (response.data.status === true) {  
          setImageLoad(false);
          onCampaignAdd.setValues({
            "instance":  onCampaignAdd.values.instance,
            "tag_id": onCampaignAdd.values.tag_id,
            "campaign_name": onCampaignAdd.values.campaign_name,  
            "template_id":  onCampaignAdd.values.template_id, 
            "template": templateData.indexOf(onCampaignAdd.values.template),
            "mobile_number": onCampaignAdd.values.mobile_number, 
            "template_name": onCampaignAdd.values.template_name, 
            "language_code": onCampaignAdd.values.language_code, 
            "header":  response.data?.file_url, 
            "headerType":  onCampaignAdd.values.headerType,  
            "parameters": onCampaignAdd.values.parameters, 
            "footer": onCampaignAdd.values.footer, 
            "type":  onCampaignAdd.values.type, 
           // "button": onCampaignAdd.values.button, 
          })
        } else {
          setImageLoad(null);
          handleNotificationCall("error", response.data.message);   
        }
    }) 
  }else if(name === "document" && event.target.files[0]?.size < 100000000 && (event.target.files[0].type === "text/plain" || event.target.files[0].type === "application/pdf" || event.target.files[0].type === "application/vnd.ms-powerpoint"
  || event.target.files[0].type === "application/msword"   || event.target.files[0].type === "application/vnd.ms-excel" || event.target.files[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  || event.target.files[0].type === "application/vnd.openxmlformats-officedocument.presentationml.presentation"   || event.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")  ){   

     // setLoader(true); 
      setImageLoad(true);
       
      data.append("file", event.target.files[0]);
       uploadFile(data, {token:  token}).then((response) => {    
      
        if (response.data.status === true) {  
          setImageLoad(false);
          onCampaignAdd.setValues({
            "instance":  onCampaignAdd.values.instance,
            "tag_id": onCampaignAdd.values.tag_id,
            "campaign_name": onCampaignAdd.values.campaign_name,  
            "template_id":  onCampaignAdd.values.template_id, 
            "template": templateData.indexOf(onCampaignAdd.values.template),
            "mobile_number": onCampaignAdd.values.mobile_number, 
            "template_name": onCampaignAdd.values.template_name, 
            "language_code": onCampaignAdd.values.language_code, 
            "header":  response.data?.file_url, 
            "headerType":  onCampaignAdd.values.headerType,  
            "parameters": onCampaignAdd.values.parameters, 
            "schedule":  onCampaignAdd.values.schedule, 
            "type":  onCampaignAdd.values.type, 
           // "footer": onCampaignAdd.values.footer, 
           // "button": onCampaignAdd.values.button, 
          })
        } else {
          setImageLoad(null);
          handleNotificationCall("error", response.data.message);   
        }
    }) 
    } else{
    
      handleNotificationCall("error", "Please check your uploaded file format and size");  
    } 
   
  
   }

   function removeTagCampaignNo(data){ 
    setLoader(true);
    removeTagCampaignNumber(data, {token: token}).then((response) => {    
     if (response.data.status === true) {
       handleNotificationCall("success", response.data.message);  
       
       handleBulkRemoveClose();
       handleRemoveClose(); 
      
       viewCampaignMessageStatus({page:1, id: campaignView.data?.id, role_code: user?.role_code, token: token}).then((result) => {    
        if (result) {    
   
          setCampaignView({
            ...campaignView,
            data: campaignView.data,
            table: result.data.data
          });
         
          setCount(result.data.count);

            }  else{
              setCount(0);
            }

          }) 

     } else {
       handleNotificationCall("error", response.data.message);  
     }
    
     setLoader(false);
   }) 
   .catch(function (error) {
    console.log(error);
  })

  }

  const [messageData, setMessageData] = React.useState({
    "headerField": false,
    "bodyField": false,
    "footerField": false,
   // "buttonField": false,
    "message": ""
  });
 

  function messageText(e){
    var loop = (e.target.value).split(",");
  
    var message = messageData.message;

    for (let i = 0; i < loop.length; i++) { 
    const regex = new RegExp(`<b id="${i+1}">.*?</b>`, 'g');
    const newText = '<b id="'+[i+1]+'">'+loop[i]+'</b>'; 
    message = message.replace(regex, newText);
    setMessageData({
      ...messageData,
      "message": message.replace(regex, newText)
    }) 
   }  
  }

  function selectTemplate(value){
    setLoader(true);
    setImageLoad(null);
    if(value?.id){
      viewTemplate({id: value.id, instance_id: instanceId?.id, token: token}).then((response) => {    
        if (response.data.status === true) {  
          const regex = /\{\{.*?\}\}/g;
         
          var HEADER = JSON.parse(response.data.data.components).filter(type => type?.type === "HEADER")[0]?.format;
          var BODY = JSON.parse(response.data.data.components).filter(type => type?.type === "BODY")[0]?.text;
         // var FOOTER = JSON.parse(response.data.data.components).filter(type => type?.type === "FOOTER")[0]?.text;


          setTemplateHeader(HEADER);
          onCampaignAdd.setValues({
            "instance":  onCampaignAdd.values.instance,
            "template": templateData.indexOf(onCampaignAdd.values.template),
            "tag_id": onCampaignAdd.values.tag_id,
            "campaign_name": onCampaignAdd.values.campaign_name,  
            "template_id":  templateData.indexOf(value), 
            "mobile_number":  onCampaignAdd.values.mobile_number, 
            "template_name": response.data.data.name, 
            "language_code": response.data.data.language, 
            "header":  response.data.data.fileUrl === null? "" :response.data.data.fileUrl, 
            "headerType":  HEADER !==undefined? HEADER: "", 
             "vars":  BODY !==undefined? (BODY.match(regex) || []).join(', ') : "", 
             "schedule":  onCampaignAdd.values.schedule, 
             "type":  onCampaignAdd.values.type, 
            //  "footer":  FOOTER !==undefined? FOOTER: "", 
            // "button": (JSON.parse(response.data.data.components).filter(type => type?.type === "BUTTONS")[0]?.text.match(regex) || []).join(', '), 

          })
           
          var loop = ((JSON.parse(response.data.data.components).filter(type => type?.type === "BODY")[0]?.text.match(regex) || []).join(',')).split(",");
    
          var message = JSON.parse(response.data.data.components).filter(type => type?.type === "BODY")[0]?.text;
  
          for (let i = 0; i < loop.length; i++) {
            const match = `{{${i+1}}}`;  
            message = message.replace(match,`<b id="${i+1}">{{${i+1}}}</b>`); 
  
            setMessageData({
              ...messageData,
              "headerField": JSON.parse(response.data.data.components).filter(type => type?.type === "HEADER")[0]?.format !==undefined ? false : true,
              "bodyField": JSON.parse(response.data.data.components).filter(type => type?.type === "BODY")[0]?.text !==undefined ? false : true,
            //  "footerField": JSON.parse(response.data.data.components).filter(type => type?.type === "FOOTER")[0]?.text !==undefined ? false : true,
              "message": message
            })
           
          }
  
     
        }  
        setLoader(false);
        
      }) 
      .catch(function (error) {
       console.log(error);
     })
  
    } else{
      setLoader(false);
      onCampaignAdd.setValues({
        "instance":  null,
        "template":  null, 
        "tag_id": onCampaignAdd.values.tag_id,
        "campaign_name": onCampaignAdd.values.campaign_name,  
        "template_id":  onCampaignAdd.values.template_id, 
        "mobile_number":  onCampaignAdd.values.mobile_number, 
        "template_name": onCampaignAdd.values.template_name, 
        "language_code": onCampaignAdd.values.language_code, 
        "header":  onCampaignAdd.values.header, 
        "headerType":  onCampaignAdd.values.headerType, 
        "parameters":  onCampaignAdd.values.parameters, 
        "schedule":  onCampaignAdd.values.schedule, 
        "type":  onCampaignAdd.values.type, 
        //"footer": onCampaignAdd.values.footer, 
       // "button": onCampaignAdd.values.button, 
       })
    }
    
  }


  function handleShow(values, name) {
    setLoader(true);
    if (name === "EDIT") {
      setDataList("EDIT");
    } else {
      setDataList("VIEW");
    } 
 
    setPage(0);
    setCurrerntPage(1);
    viewTagCampaign({id: values, token: token}).then((response) => {    
      if (response.data.status === true) {    
  
        setCampaignView({
          ...campaignView,
          data: response.data.data,
          table: []
        });
        
        viewCampaignMessageStatus({page:1, id: values, role_code: user?.role_code, token: token}).then((result) => {    
          if (result.data.status === true) {    
     
            setCampaignView({
              ...campaignView,
              data: response.data.data,
              table: result.data.data
            });
           
            setCount(result.data.count);

              }  else{
                setCount(0);
              }
            }) 
              .catch(function (error) {
               console.log(error);
             })

             setState({ ...state, right: true });
            setLoader(false);
          } 
        }) 
         
  }
 

  function resendCampaign(values){
    setLoader(true);
    rerunTagCampaign({campaign_id: values, token: token}).then((response) => {    
      if (response.data.status === true) {    
        handleResendClose(); 
        handleNotificationCall("success", response.data.message);   
          }  else {
            handleNotificationCall("error", response.data.message);   
          }

          setLoader(false);
        }) 
          .catch(function (error) {
           console.log(error);
         })
  }

  const [state, setState] = useState({ 
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {  
    setState({ ...state, [anchor]: open });
  }; 

  const SelectElements = () => <Grid className={classes.gap10}>  
 
  <Button
   variant="contained"
   color="secondary"
   size="small"
   startIcon={<DeleteForeverIcon />}
   onClick={() =>{
    setselectedId(selectId);
    handleBulkRemoveOpen();
   
   }}
  >
  Remove Contact
  </Button>
  </Grid>;




var table_data = {};

var columns_data = [];

if (user?.role_code === "ADMIN") {
 columns_data = [
  {
    name: "S.No",
  },
     
  {
    name: "Instance Name",
  },
  {
    name: "Campaign Name",
  },

  {
    name: "Tag Name",
  },

  {
    name: "Template Name",
  }, 
  {
    name: "Status",
  }, 
  {
    name: "Created By",
  },
  {
    name: "Posted Date",
  },
  {
    name: "Actions",
  },
];

table_data = campaignData?.map((item, index) => {

              
  return [
    campainCurrerntPage !== 0 ? 10 * campainCurrerntPage - 10 + index + 1   : index + 1 ,  
   item.instance_name,
   item.campaign_name,
   item.tag_name,
   item.template_name,  
   <>{item.status } <br/> {item.status === "Scheduled"? moment(moment(item.scheduled).format('YYYY-MM-DD[T]HH:mm:ss[Z]')).tz(moment.tz.guess()).format('DD-MM-YYYY HH:mm A') : "" }</>,
   item?.createdBy,
   item?.created? moment(item?.created).tz(moment.tz.guess()).format("DD-MM-YYYY") :"",

   <>
     <Grid container className={classes.space}>
       <Grid item xs className={classes.toolAlign}>
       {item.status === "Campaigned" ?
       <Tooltip
           title="Resend Campaign"
           placement="bottom"
           aria-label="view"
         >
           <SendIcon
             className={classes.toolIcon}
             onClick={(e) => {
               handleResendOpen();
               setId(item.id);
             }}
           />
         </Tooltip>
         :""} 

         <Tooltip
           title="View Campaign"
           placement="bottom"
           aria-label="view"
         >
           <ViewIcon
             className={classes.toolIcon}
             onClick={(e) => {
               handleShow(item.id, "VIEW");
             }}
           />
         </Tooltip>
         
      
       </Grid>
     </Grid>
   </>,
 ];
})

} else{
columns_data = [
  {
    name: "S.No",
  },
     
  {
    name: "Instance Name",
  },
  {
    name: "Campaign Name",
  },

  {
    name: "Tag Name",
  },

  {
    name: "Template Name",
  }, 
  {
    name: "Status",
  }, 
  {
    name: "Posted Date",
  },
  {
    name: "Actions",
  },
];

table_data = campaignData?.map((item, index) => {

              
  return [
    campainCurrerntPage !== 0 ? 10 * campainCurrerntPage - 10 + index + 1   : index + 1 ,  
   item.instance_name,
   item.campaign_name,
   item.tag_name,
   item.template_name,  
   <>{item.status } <br/> {item.status === "Scheduled"? moment(moment(item.scheduled).format('YYYY-MM-DD[T]HH:mm:ss[Z]')).tz(moment.tz.guess()).format('DD-MM-YYYY HH:mm A') : "" }</>,
   item?.created? moment(item?.created).tz(moment.tz.guess()).format("DD-MM-YYYY") :"",

   <>
     <Grid container className={classes.space}>
       <Grid item xs className={classes.toolAlign}>
       {item.status === "Campaigned" ?
       <Tooltip
           title="Resend Campaign"
           placement="bottom"
           aria-label="view"
         >
           <SendIcon
             className={classes.toolIcon}
             onClick={(e) => {
               handleResendOpen();
               setId(item.id);
             }}
           />
         </Tooltip>
         :""} 

         <Tooltip
           title="View Campaign"
           placement="bottom"
           aria-label="view"
         >
           <ViewIcon
             className={classes.toolIcon}
             onClick={(e) => {
               handleShow(item.id, "VIEW");
             }}
           />
         </Tooltip>
         
      
       </Grid>
     </Grid>
   </>,
 ];
})
}

  const list = (anchor) =>
  dataList === "ADD" ? (
    <Box sx={{ width: "100%" }} role="presentation">
    <List>
      <Card >
        <CardHeader>
          <Grid
            container
            direction="row"
            spacing={1}
            className={classes.drawerHeader}
          >
            <Typography variant="subtitle1"> Add Campaign</Typography>

            <Grid className={classes.drawerClose}>
              <CloseIcon
                className={classes.closeBtn}
                size="14px"
                onClick={toggleDrawer(anchor, false)}
              />
            </Grid>
          </Grid>
        </CardHeader>

       
     <form onSubmit={onCampaignAdd.handleSubmit}  >

    <CardContent className={classes.drawerContent}>
        <Grid container direction="row" spacing={2}>

    
        <Grid item xs={12} sm={6} md={6} lg={6}>
            
            <FormControl className={classes.margin}>
             
   <Autocomplete  underlineShow={false}
     options={instanceData}
     
     getOptionLabel={(option) =>
      option.name !==undefined? `${ option.name } ` : ''
     }
      value={ instanceValue }
     onChange={(e, value) => {  
      setInstanceValue(value);

      onCampaignAdd.setValues({
        
        "instance":   instanceData.indexOf(value), 
        "template": templateData.indexOf(onCampaignAdd.values.template),
        "tag_id": onCampaignAdd.values.tag_id,
        "campaign_name": onCampaignAdd.values.campaign_name,  
        "template_id":  onCampaignAdd.values.template_id, 
        "mobile_number":  onCampaignAdd.values.mobile_number, 
        "template_name": onCampaignAdd.values.template_name, 
        "language_code": onCampaignAdd.values.language_code, 
        "header":  onCampaignAdd.values.header, 
        "headerType": onCampaignAdd.values.headerType, 
        "vars":  onCampaignAdd.values.vars, 
        "schedule":  onCampaignAdd.values.schedule, 
        "type":  onCampaignAdd.values.type, 
       // "footer": onCampaignAdd.values.footer, 
       // "button": onCampaignAdd.values.button, 
      })
     
       }
     } 
     renderInput={(params) => (
       <TextField
       label={"Select Instance"}
         {...params}
         name="instance" 
         fullWidth
         classes={{ root: classes.customTextField }}
         InputLabelProps={{ shrink: true }} 
         type="text"
         error={onCampaignAdd.touched.instance && Boolean(onCampaignAdd.errors.instance)}
         helperText={onCampaignAdd.touched.instance && onCampaignAdd.errors.instance} 

       />
     )}
   />
 
            </FormControl>
          </Grid>


        <Grid item xs={12} sm={6} md={6} lg={6}>
            <InputLabel shrink htmlFor="campaign_name">  Campaign Name    </InputLabel>

            <FormControl className={classes.margin}>
              <TextField
                classes={{ root: classes.customTextField }}
                InputProps={{ disableUnderline: true }}
                size="small"
                placeholder="Enter campaign name"
                type="text"
                name="campaign_name" 
                value={onCampaignAdd.values.campaign_name}
                onChange={onCampaignAdd.handleChange}
                error={onCampaignAdd.touched.campaign_name && Boolean(onCampaignAdd.errors.campaign_name)}
                helperText={onCampaignAdd.touched.campaign_name && onCampaignAdd.errors.campaign_name}

              />
 
            </FormControl>
          </Grid>
         

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <InputLabel shrink htmlFor="tag_id"> Tag Name </InputLabel>
            <Autocomplete  underlineShow={false}
            options={tagData}
            
            getOptionLabel={(option) =>

              option?.name !==undefined? 
              user?.role_code ==="ADMIN"? `${ option?.name + " ("+ option?.createdby + ")" }`
              : option?.name :""
            }
            
            value={tagData[onCampaignAdd.values.tag_id]} 
            onChange={(e, value) => { 
              onCampaignAdd.setValues({  
                "instance":  onCampaignAdd.values.instance, 
                "template": templateData.indexOf(onCampaignAdd.values.template),
                "tag_id": tagData.indexOf(value),
                "campaign_name": onCampaignAdd.values.campaign_name,
                "template_id": onCampaignAdd.values.template_id,
                "mobile_number":  onCampaignAdd.values.mobile_number, 
                "template_name": onCampaignAdd.values.template_name, 
                "language_code": onCampaignAdd.values.language_code, 
                "header":   onCampaignAdd.values.header, 
                "headerType":  onCampaignAdd.values.headerType, 
                "vars":  onCampaignAdd.values.vars, 
                "schedule":  onCampaignAdd.values.schedule, 
                //"footer":  onCampaignAdd.values.footer, 
                "type":  onCampaignAdd.values.type, 
                // "button": onCampaignAdd.values.button, 
              })

             }
            } 
            renderInput={(params) => (
              <TextField
              classes={{ root: classes.customTextField }}
                {...params}
                name="tag_id" 
                InputLabelProps={{ shrink: true }}
                type="text"
                error={onCampaignAdd.touched.tag_id && Boolean(onCampaignAdd.errors.tag_id)}
                helperText={onCampaignAdd.touched.tag_id && onCampaignAdd.errors.tag_id} 

              />
            )}
          />
            
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
          
           
 <InputLabel shrink htmlFor="template"> Select Template </InputLabel>
<Autocomplete  underlineShow={false}
  options={templateData}
  
  getOptionLabel={(option) =>
    `${ option.name } `
  }
  
  value={templateData[onCampaignAdd.values.template_id]} 
  onChange={(e, value) => { 
    onCampaignAdd.setValues({ 
      "instance":  onCampaignAdd.values.instance,
      "template": templateData.indexOf(onCampaignAdd.values.template),
    "tag_id":  onCampaignAdd.values.tag_id,
    "campaign_name": onCampaignAdd.values.campaign_name,
    "template_id": templateData.indexOf(value),
    "mobile_number":  onCampaignAdd.values.mobile_number, 
    "template_name": onCampaignAdd.values.template_name, 
    "language_code": onCampaignAdd.values.language_code, 
    "header":   onCampaignAdd.values.header, 
    "headerType":  onCampaignAdd.values.headerType, 
    "vars":  onCampaignAdd.values.vars, 
    "type":  onCampaignAdd.values.type, 
    "schedule":  onCampaignAdd.values.schedule, 
   // "footer":  onCampaignAdd.values.footer, 
   // "button": onCampaignAdd.values.button, 
   });
    selectTemplate(value); 
    }
  } 
  renderInput={(params) => (
    <TextField
    classes={{ root: classes.customTextField }}
    {...params} 
    InputLabelProps={{ shrink: true }}
    name="template_id" 
    type="text"
      error={onCampaignAdd.touched.template_id && Boolean(onCampaignAdd.errors.template_id)}
      helperText={onCampaignAdd.touched.template_id && onCampaignAdd.errors.template_id} 

    />
  )}
/> 

          </Grid>


          {/* <Grid item xs={12} sm={6} md={6} lg={6}> 

       
<TextField  
 label="Name" 
 name="template_name"
placeholder="Enter name"
 fullWidth
 margin="normal"
InputLabelProps={{
  shrink: true, 
}}
InputProps={{
  readOnly: true,
  disableUnderline: true 
}}
 
classes={{ root: classes.customTextField }}
value={onCampaignAdd.values.template_name} 
onChange={onCampaignAdd.handleChange} 
  
/> 


</Grid>


<Grid item xs={12} sm={6} md={6} lg={6}> 

<FormControl className={classes.margin}>
<TextField 
 label="Language"
 name="language_code"
 placeholder="Enter language"
  fullWidth
  margin="normal"
 InputLabelProps={{
   shrink: true, 
 }}
 InputProps={{
  readOnly: true,
}}
 
 classes={{ root: classes.customTextField }}
 value={onCampaignAdd.values.language_code}
 onChange={onCampaignAdd.handleChange} 
 
/> 

</FormControl>
</Grid> */}

<Grid item xs={12} sm={6} md={6} lg={6}> 

<FormControl className={classes.margin}>
<TextField 
 label="Header"
 name="header"
 placeholder="Enter header"
  fullWidth
  margin="normal"
 InputLabelProps={{
   shrink: true,
 }}
 InputProps={{
  readOnly: true,
}}
  
 classes={{ root: classes.customTextField }}
 value={onCampaignAdd.values.header}
   onChange={onCampaignAdd.handleChange}
   error={onCampaignAdd.touched.header && Boolean(onCampaignAdd.errors.header)}
      helperText={onCampaignAdd.touched.header && onCampaignAdd.errors.header} 

/> 


</FormControl>


{ onCampaignAdd.values.headerType ==="IMAGE"? <> 
  <Grid item xs={12}  > 
 
<FormControl className={classes.margin}    > 
<div style={{ display: "flex", gap:10}}> 
<TextField 
  id="image"
type="file" 
style={{ display: "none" }} 
 name="file_path" 
 InputLabelProps={{
shrink: true,
}}
 onChange={(e)=>{
   handleSendTemplateImage(e, "image");
 }}
 inputProps={{accept:"image/jpeg, image/png"}} 
/>

                   <label htmlFor="image">
                     <Button
                       variant="contained"
                       color={ "primary" } 
                       className={classes.button}
                       startIcon={<FcGallery />}
                       aria-label="upload image"
                       component="span"
                     >
                       Upload image 
                     </Button> 
                     
                     { fileData?.image?.name ?
                         
                           <Typography >{ fileData?.image?.name}  </Typography>  :""
                      } 
                   </label>
                   { imageLoad === false?  <CheckCircleIcon style={{ fontSize: 30, color:"green" }} />  : imageLoad === true? <CircularProgress color="inherit"  style={{  color:"blue" }} /> :""}
  
                  
                  
                 </div>
                
</FormControl>



  </Grid> 
    
  </>
: onCampaignAdd.values.headerType ==="VIDEO"? 
<> 
<Grid item xs={12}  > 

<FormControl className={classes.margin}    > 
<div style={{ display: "flex", gap:10}}> 
<TextField 
id="video"
type="file" 
style={{ display: "none" }} 
name="file_path" 
InputLabelProps={{
shrink: true,
}}
onChange={(e)=>{
 handleSendTemplateImage(e, "video");
}}
inputProps={{accept:"video/mp4, video/3gp"}} 
/>

                 <label htmlFor="video">
                   <Button
                     variant="contained"
                     color={ "primary" } 
                     className={classes.button}
                     startIcon={<FcVideoFile />}
                     aria-label="upload video"
                     component="span"
                   >
                     Upload video 
                   </Button> 
                   
                   { fileData?.video?.name?
                       
                           <Typography >{ fileData?.video?.name}  </Typography>  :""
                      } 
                 </label>
                 { imageLoad === false?  <CheckCircleIcon style={{ fontSize: 30, color:"green" }} />  : imageLoad === true? <CircularProgress color="inherit" style={{  color:"blue" }} /> :""}

                
                
               </div>
              
</FormControl>



</Grid> 
  
</>

: onCampaignAdd.values.headerType ==="DOCUMENT"?
<> 
<Grid item xs={12}  > 

<FormControl className={classes.margin}    > 
<div style={{ display: "flex", gap:10}}> 
<TextField 
id="document"
type="file" 
style={{ display: "none" }} 
name="file_path" 
InputLabelProps={{
shrink: true,
}}
onChange={(e)=>{
 handleSendTemplateImage(e, "document");
}}
inputProps={{accept:"text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}} 
/>

                 <label htmlFor="document">
                   <Button
                     variant="contained"
                     color={ "primary" } 
                     className={classes.button}
                     startIcon={<FcDocument />}
                     aria-label="upload document"
                     component="span"
                   >
                     Upload document 
                   </Button> 
                   
                   
                   { fileData?.document?.name  ?
                         
                           <Typography >{ fileData?.document?.name}  </Typography>  :""
                      } 
                 </label>
                 { imageLoad === false?  <CheckCircleIcon style={{ fontSize: 30, color:"green" }} />  : imageLoad === true? <CircularProgress color="inherit"  style={{  color:"blue" }} /> :""}

                
                
               </div>
              
</FormControl>



</Grid> 
  
</>
:""}
</Grid>

<Grid item xs={12} sm={6} md={6} lg={6}> 

<FormControl className={classes.margin}>
<TextField 
 label="Body"
 name="vars"
 placeholder="Enter body"
  fullWidth
  margin="normal"
 InputLabelProps={{
   shrink: true,
 }}

 InputProps={{
  readOnly: messageData.bodyField,
}}
 
 classes={{ root: classes.customTextField }}
 value={onCampaignAdd.values.vars}
 onChange={onCampaignAdd.handleChange}
 onBlur={(e)=>{ 
  messageText(e); 
 }}
  error={onCampaignAdd.touched.vars && Boolean(onCampaignAdd.errors.vars)}
  helperText={onCampaignAdd.touched.vars && onCampaignAdd.errors.vars} 
/> 

</FormControl>
</Grid>

<Grid item xs={12} sm={6} md={6} lg={6}> 
<FormControl className={classes.formControl}>
        <InputLabel shrink id="type"> Type </InputLabel>
        <Select
          labelId="type"
          id="type"
            value={onCampaignAdd.values.type}
           onChange={(e, value) => { 
            onCampaignAdd.setValues({ 
              "instance":  onCampaignAdd.values.instance,
              "template": templateData.indexOf(onCampaignAdd.values.template),
            "tag_id":  onCampaignAdd.values.tag_id,
            "campaign_name": onCampaignAdd.values.campaign_name,
             "template_id": onCampaignAdd.values.template_id, 
            "mobile_number":  onCampaignAdd.values.mobile_number, 
            "template_name": onCampaignAdd.values.template_name, 
            "language_code": onCampaignAdd.values.language_code, 
            "header":   onCampaignAdd.values.header, 
            "headerType":  onCampaignAdd.values.headerType, 
            "vars":  onCampaignAdd.values.vars, 
            "type":  e.target.value,  
           });
          
            }
          }
          classes={{ root: classes.customSelectField }}

        
        > 
          <MenuItem value={"Immediate"}>Immediate</MenuItem>
          <MenuItem value={"Scheduled"}>Schedule</MenuItem>
         </Select>
       
      </FormControl>
</Grid>


<Grid item xs={12} sm={6} md={6} lg={6}> 

{onCampaignAdd.values.type === "Scheduled" ? 
<TextField
 classes={{ root: classes.customTextField }}
            name="schedule"
           label="Scheduled"
            InputLabelProps={{ shrink: true }}
            inputProps={{ 
              min: moment().tz(moment.tz.guess()).format("YYYY-MM-DDTHH:mm")
            }} 
            type="datetime-local"
            value={onCampaignAdd.values.schedule}
            onChange={onCampaignAdd.handleChange}
            error={onCampaignAdd.touched.schedule && Boolean(onCampaignAdd.errors.schedule)}
            helperText={onCampaignAdd.touched.schedule && onCampaignAdd.errors.schedule} 
            required
          />
:""}

</Grid>

{/* <Grid item xs={12} sm={6} md={6} lg={6}> 

<FormControl className={classes.margin}>
<TextField 
  label="Footer"
  placeholder="Enter footer"
  fullWidth
  margin="normal"
  name="footer"
 InputLabelProps={{
   shrink: true,
 }}
 InputProps={{
  readOnly: messageData.footerField,
}}

 
 classes={{ root: classes.customTextField }}
 value={onCampaignAdd.values.footer}
  onChange={onCampaignAdd.handleChange}
   
/> 

</FormControl>
</Grid> */}


{/* <Grid item xs={12} sm={6} md={6} lg={6}> 

<FormControl className={classes.margin}>
<TextField 
  label="Button"
  placeholder="Enter button"
  fullWidth
  margin="normal"
  name="button"
 InputLabelProps={{
   shrink: true,
 }}
 InputProps={{
  readOnly: messageData.buttonField,
}}

 
 classes={{ root: classes.customTextField }}
 value={onCampaignAdd.values.button}
  onChange={onCampaignAdd.handleChange}
   
/> 

</FormControl>
</Grid> */}

          <Grid item xs={12}      >

<Grid className={classes.p24x8}> 

<Typography variant='h6' className={classes.center}> {messageData.message? "Preview"  :"" } </Typography> <br/> 


<Typography variant='text' > 
{onCampaignAdd.values.header && onCampaignAdd.values.headerType === "IMAGE"?
<img src={onCampaignAdd.values.header} alt="logo" style={{ width: '100%', height: "250px"}}  />  
 : onCampaignAdd.values.header && onCampaignAdd.values.headerType === "VIDEO"?
 <video controls width={"100%"}>
 <source src={onCampaignAdd.values.header} /> 
</video>  
: onCampaignAdd.values.header && onCampaignAdd.values.headerType ==="DOCUMENT"?
<iframe src={`https://docs.google.com/a/umd.edu/viewer?url=${onCampaignAdd.values.header}&embedded=true`} title="File" width="100%" height="200" > </iframe>
 
: ""}
 <div ref={messageRef} dangerouslySetInnerHTML={{__html: messageData.message}}  />  </Typography>


</Grid>

</Grid>

        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.drawerFooter}
        >
        

          <Button
            variant="contained"
            color="primary"
            size="small"
             type="submit"
             disabled={onCampaignAdd.isSubmitting}
          >
            Add
          </Button>

          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={toggleDrawer(anchor, false)}
          >
            
            Close
          </Button>
        </Grid>
      </CardActions>
    </form>
    </Card>
        </List>
        </Box>
 
  ) : (
      <>
        <Box sx={{ width: "100%" }} role="presentation">
          <List>
            <Card  >
              <CardHeader>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  className={classes.drawerHeader}
                >
                  <Typography variant="subtitle1"> View Campaign</Typography>

                  <Grid className={classes.drawerClose}>
                    <CloseIcon
                      className={classes.closeBtn}
                      size="14px"
                      onClick={toggleDrawer(anchor, false)}
                    />
                  </Grid>
                </Grid>
              </CardHeader>

              <CardContent className={classes.drawerContent}>
                <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>   <Typography className={classes.boldtext}> Instance Name  </Typography> </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {campaignView.data?.instance_name}  </Grid> 

                  <Grid item xs={12} sm={6} md={6} lg={6}>   <Typography className={classes.boldtext}>  Campaign Name  </Typography> </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {campaignView.data?.campaign_name}  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>    <Typography className={classes.boldtext}>   Tag Name  </Typography>     </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {campaignView.data?.tag_name}    </Grid> 


                  <Grid item xs={12} sm={6} md={6} lg={6}>    <Typography className={classes.boldtext}>   Template Name  </Typography>     </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {campaignView.data?.template_name}    </Grid>  


                  <Grid item xs={12} sm={6} md={6} lg={6}>    <Typography className={classes.boldtext}>   Type  </Typography>     </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {campaignView.data?.type === "Scheduled"? "Schedule" : campaignView.data?.type }     </Grid>  
                 
                  {campaignView.data?.type === "Scheduled"? <>  
                  <Grid item xs={12} sm={6} md={6} lg={6}>    <Typography className={classes.boldtext}>   Schedule   </Typography>     </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6} style={{  display: "flex"}}>   
                
                  
                  {campaignView.data?.status !== "Scheduled" ?
                 
                 moment(moment(campaignView.data?.scheduled).format('YYYY-MM-DD[T]HH:mm:ss[Z]')).tz(moment.tz.guess()).format('DD-MM-YYYY HH:mm A'):
<>
<form onSubmit={onCampaignRescheduled.handleSubmit} className={classes.form}  >

<TextField
classes={{ root: classes.customTextField }}
name="schedule" 
InputLabelProps={{ shrink: true }}
inputProps={{

min: moment().tz(moment.tz.guess()).format("YYYY-MM-DD")
}} 
type="datetime-local"
defaultValue={moment(moment(campaignView.data?.scheduled).format('YYYY-MM-DD[T]HH:mm:ss[Z]')).tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm')} 
onChange={onCampaignRescheduled.handleChange}
error={onCampaignRescheduled.touched.schedule && Boolean(onCampaignRescheduled.errors.schedule)}
helperText={onCampaignRescheduled.touched.schedule && onCampaignRescheduled.errors.schedule} 
required
/>


<Button
variant="contained"
color="primary"
size="small"
type="submit"
className={classes.marginTop}
disabled={onCampaignRescheduled.isSubmitting}
>
Save
</Button>

</form>

</>

                }
 
                  
                  
                    </Grid>  
                  
                  </> :"" }  


                  <Grid item xs={12} sm={6} md={6} lg={6}>    <Typography className={classes.boldtext}>   Status</Typography>     </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {campaignView.data?.status}    </Grid>  


                  <Grid item xs={12} sm={6} md={6} lg={6}> <Typography className={classes.boldtext}> Posted Date   </Typography> </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}> {moment(campaignView.data?.created).tz(moment.tz.guess()).format("DD-MM-YYYY")}  </Grid> 
             
                  <Grid item xs={12} > 
                  <MUIDataTable
            options={{
              onRowSelectionChange: (currentRowsSelected, allRowsSelected) => handleRowSelectionChange(allRowsSelected),

              pagination: false,
              search: false,
              download: false,
              print: false,
              viewColumns: true,
              filter: false,
              filterType: "dropdown",
              responsive: mobileQuery===true? 'vertical' : 'standard',
              customToolbarSelect: () => <SelectElements />,
              customToolbar: () => <HeaderElements />,
              textLabels: {
                body: {
                  noMatch: 'Oops! Matching record could not be found',
                }
              }
            }}
            columns={[
              {
                name: "S.No",
              },
                 
              {
                name: "Name",
              },
              {
                name: "Mobile",
              },
             
              {
                name: "Status",
              }, 
              {
                name: "Email",
              },
            ]}
            data={campaignView?.table?.map((item, index) => {
 
               return [ 
                currerntPage !== 0 ? 10 * currerntPage - 10 + index + 1   : index + 1 , 
                item.name,
                item.to_number,
              
                item.status === "failed" ?  
                <Grid container className={classes.space}>
                <Grid item xs className={classes.toolAlign} style={{ alignItems: "center"}}>
                <Button color="secondary" className={classes.pointerEventsNone} style={{ width: 100}}> <CloseIcon color="secondary" style={{ fontSize:14, marginRight:5}} />   Failed </Button>
                <Tooltip
                        title="Remove Contact"
                        placement="bottom"
                        aria-label="remove"
                      >
                        <DeleteForeverIcon
                          className={classes.toolIconDelete}
                          onClick={(e) => {
                            
                            handleClickDelete(item);
                            handleRemoveOpen();
                          }}
                        />
                      </Tooltip> </Grid></Grid>
                 :   item.status === "delivered" ?  
                <Button color="primary" className={classes.pointerEventsNone} style={{ width: 100}}> <DoneAllIcon color="disabled" style={{ fontSize:14, marginRight:5}}  /> Delivered</Button> : 
                item.status === "read" ? 
                <Button style={{ color:"#33ab9f", width: 100}} className={classes.pointerEventsNone}  ><DoneAllIcon color="primary" style={{ fontSize:14, marginRight:5}} /> Read</Button> : 
                <Button style={{ color:"#35baf6", width: 100}} className={classes.pointerEventsNone}   ><DoneIcon color="disabled" style={{ fontSize:14, marginRight:5 }}  /> Sent</Button>  ,
                item.email,
              ];
            })}
          />
 <Grid container spacing={2} className={classes.pagination}>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={count}
              rowsPerPage={10}
              page={page}
              onChangePage={handleChangePage}
            />
          </Grid> 
</Grid>
             
               </Grid>



              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={classes.drawerFooter}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={toggleDrawer(anchor, false)}
                  >
                    
                    Close
                  </Button>
                </Grid>
              </CardActions>
            </Card>
          </List>
        </Box>
      </>
    );
 

  return (
    <>
      <Grid container direction="row" spacing={2} className={classes.heading}>
        <Grid item xs={9} sm={7} md={8} lg={6}>
        <PageTitle title="Campaign" />
          
        </Grid>

        <Grid item xs={3} sm={5} md={4} lg={6} className={classes.drawerClose}>
          <div className={classes.lgButton}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              startIcon={<AddCircleIcon />}
              className={classes.margin}
              onClick={(e) => {
                setDataList("ADD");
                setInstanceValue(null);
                setState({ ...state, right: true });
                onCampaignAdd.resetForm(); 
              }}
            >
              Add New Campaign
            </Button>
          </div>

          <div className={classes.smButton}>
            <Button
              variant="contained"
              size="small"
              startIcon={<AddCircleIcon />}
              className={classes.margin}
              color="primary"
              onClick={(e) => {
                setDataList("ADD");
                setInstanceValue(null);
                onCampaignAdd.resetForm(); 
                setState({ ...state, right: true });
              }}
            >
              
              Add
            </Button>
          </div>
          <SwipeableDrawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
            classes={{ paper: classes.drawer }}
            disableSwipeToOpen={false}
          >
  
            { list("right")}
          </SwipeableDrawer>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUIDataTable
            options={{
              pagination: false,
              sort: false,
              selectableRows: "none",
              search: false,
              filter: false,
              print: false,
              download: false,
              responsive: mobileQuery===true? 'vertical' : 'standard',
              customToolbar: () => <HeaderCampainElements />,
              
              textLabels: {
                body: {
                  noMatch: 'Oops! Matching record could not be found',
                }
              }
            }}
            columns={columns_data}
            data={table_data}
          />

<Grid container spacing={2} className={classes.pagination}>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={campainCount}
              rowsPerPage={10}
              page={campainPage}
              onChangePage={handleCampainChangePage}
            />
          </Grid>
         
        </Grid>
      </Grid>

      

      <Dialog onClose={handleResendClose} aria-labelledby="dialog-title" open={resendOpen} width='md' PaperProps={{ style: {  width: '100%' } }}>

{/* <DialogTitle >Are you sure you want to remove this contact? </DialogTitle>  */}
<DialogContent style={{ padding: "0px"}} >  
<DialogContentText>
 
<Grid className={classes.center +" "+classes.dialogTitle}  >

<Grid container direction="row" spacing={2}   >
<Grid item xs={12} className={classes.dialogHeader}>
<Typography  variant="subtitle1"> Are you sure you want to resend this campaign? </Typography>

    <CloseIcon   className={classes.closeBtn}  size="14px"
      onClick={(e)=>[
       handleResendClose()
      ]}
    />
  </Grid>
</Grid>
</Grid>
   </DialogContentText> 
<DialogActions> 
<Button  variant="contained"  color="primary"  size="small"  onClick={(e) => { resendCampaign(Id) }}>  Yes   </Button> 
<Button variant="contained" size="small" color="secondary" onClick={handleResendClose} >No</Button>

</DialogActions>

</DialogContent>

</Dialog>

 


<Dialog onClose={handleRemoveClose} aria-labelledby="dialog-title" open={removeOpen} width='md' PaperProps={{ style: {  width: '100%' } }}>

{/* <DialogTitle >Are you sure you want to remove this contact? </DialogTitle>  */}
<DialogContent style={{ padding: "0px"}} >  
<DialogContentText>
 
<Grid className={classes.center +" "+classes.dialogTitle}  >

<Grid container direction="row" spacing={2}   >
<Grid item xs={12} className={classes.dialogHeader}>
<Typography  variant="subtitle1"> Are you sure you want to remove this contact? </Typography>

    <CloseIcon   className={classes.closeBtn}  size="14px"
      onClick={(e)=>[
       handleRemoveClose()
      ]}
    />
  </Grid>
</Grid>
</Grid>
   </DialogContentText> 
<DialogActions> 
<Button  variant="contained"  color="primary"  size="small"   onClick={(e) => { removeTagCampaignNo(selectedId) }}>  Yes   </Button> 
<Button variant="contained" size="small" color="secondary" onClick={handleRemoveClose} >No</Button>

</DialogActions>

</DialogContent>

</Dialog>



<Dialog onClose={handleBulkRemoveClose} aria-labelledby="dialog-title" open={removeBulkOpen} width='md' PaperProps={{ style: {  width: '100%' } }}>
<DialogContent style={{ padding: "0px"}} >  
<DialogContentText>  

<Grid className={classes.center +" "+classes.dialogTitle}  >

<Grid container direction="row" spacing={2}   >
<Grid item xs={12} className={classes.dialogHeader}>
<Typography  variant="subtitle1">   Are you sure you want to remove this Contact?  </Typography>

       <CloseIcon   className={classes.closeBtn}  size="14px"
         onClick={(e)=>[
          handleBulkRemoveClose()
         ]}
       />
     </Grid>
   </Grid>
</Grid>
</DialogContentText> 
<DialogActions> 
<Button  variant="contained"  color="primary"  size="small"  onClick={(e) => { removeTagCampaignNo(selectedId) }}>  Yes   </Button> 
<Button variant="contained" size="small" color="secondary" onClick={handleBulkRemoveClose} >No</Button>

</DialogActions>

</DialogContent>

</Dialog>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

