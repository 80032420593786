import React, {useState, useEffect } from 'react';
//  import Container from '@material-ui/core/Container';
// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import CardHeader from '@material-ui/core/CardHeader';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types'; 
import Authentication from './Authentication';
import Template from './Template';
import SendMessage from './SendMessage';

import Message from './Message';
import Settings from './Settings';
import {  useHistory } from "react-router-dom";

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { viewAuthentication, deleteInstance, restartInstance, resetInstance } from '../../services/instance.api'; 
// import { getAllPlans } from '../../services/plans.api'; 
// import { addUserTransaction, getPaymentSuccess } from '../../services/transaction.api'; 
import CloseIcon from "@material-ui/icons/Close"; 
// import useRazorpay from "react-razorpay"; 
 
import {  FcPrivacy, FcNews, FcVoicePresentation, FcSettings } from "react-icons/fc"; 
import {  AiOutlineClear } from "react-icons/ai"; 
import {  RiRestartFill } from "react-icons/ri"; 



import useStyles from "./style";
import globalStyles from "../../themes/style";
import Notification from "../../components/Notification/Notification";
import { toast } from "react-toastify";
  import { 
  Button, Grid, Typography, Box, Avatar,
  Dialog, DialogContent, DialogActions, 
 
} from "@material-ui/core";
 
function TabPanel(props) {
  const globalClasses = globalStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={index === 3? globalClasses.tabpanel3:""}  >
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

    TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
    }

    // const StyledBadge = withStyles((theme) => ({
    // badge: {
    // backgroundColor: '#44b700',
    // color: '#44b700',
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    // '&::after': {
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // width: '100%',
    // height: '100%',
    // borderRadius: '50%',
    // animation: '$ripple 1.2s infinite ease-in-out',
    // border: '1px solid currentColor',
    // content: '""',
    // },
    // },
    // '@keyframes ripple': {
    // '0%': {
    // transform: 'scale(.8)',
    // opacity: 1,
    // },
    // '100%': {
    // transform: 'scale(2.4)',
    // opacity: 0,
    // },
    // },
    // }))(Badge);
  

export default function Home(props) {
  const classes = useStyles();
  const globalClasses = globalStyles();

  
  const queryParams = new URLSearchParams(props.location.search);
 const values = queryParams.get("value");
 const mobile = queryParams.get("mobile");

 
  // const [payOpen, setPayOpen] = useState(false);

  // const [Razorpay] = useRazorpay();

  // const handlePayClose = () => {
  //   setPayOpen(false);
     
  // };

  // const handlePayOpen = (url) => {
  //   setPayOpen(true); 
  // };
  const [loader, setLoader] = useState(false);
 
  //var moment = require('moment-timezone');
  const [restart, setRestart] = React.useState(false);
  const [reset, setReset] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push("/app/instance");
  };
 
  const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token"); 
 
  const [instanceId, setInstanceId] = React.useState();
  // const [planList, setPlanList] = React.useState([]);

  const history = useHistory(); 

  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress, 
          className: classes.notification,
        },
    );
  }

  const [value, setValue] = React.useState(parseInt(values? values: 0));
 
  const [removeOpen, setRemoveOpen] = React.useState(false);
  
  const handleRemoveOpen = () => {
    setRemoveOpen(true);
  };

  const handleRemoveClose = () => {
    setRemoveOpen(false);
  };


  useEffect(() => {
 

    if(user?.role_code !== "SUPERADMIN"){
 
      const changeInstanceId = () => { 
        getInstanceId(user?.userId);

      }; 
    
    

     getInstanceId(user?.userId);
      window.addEventListener("storage", changeInstanceId);
      return () => {
        window.removeEventListener("storage", changeInstanceId);
      };
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []); 


function getInstanceId(id){ 
 
  viewAuthentication({userId: id, token: token}).then((response) => {   
     
    if (response.data.status === true) {   
      setInstanceId(JSON.parse(JSON.stringify(response?.data?.data))); 
 
  
       if(parseInt(response?.data?.data?.istrash) === 1){
        setReset(true); 
         
       }
       
     } else{


   
      if(user?.role_code === "USER"){
        handleNotificationCall("error", "Instance not found");  
        setTimeout(() => {
        history.push('dashboard');
     }, 5000);
    }
         
     }
  }) 
  .catch(function (error) {
   console.log(error);
 })
}
 

// function getAllPlanList(){
//   getAllPlans({id: user?.userId, token:token}).then((response) => {   
     
 
//     if (response.data.status === true) {   
 
//       handlePayOpen();
//       setPlanList(response.data.data); 
//      }  
//   }) 
//   .catch(function (error) {
//    console.log(error);
//  })
// }


function onDeleteInstance(){
  setLoader(true);
  deleteInstance({id: instanceId?.id, token: token}).then((response) => {   
     
    if (response.data.status === true) {  
     
       
      handleNotificationCall("success", response.data.message);  
      setInstanceId();
      setValue(user?.role_code==="ADMIN"?0:1);
     } else{
      handleNotificationCall("error", response.data.message);  

    }

    handleRemoveClose();
    setLoader(false);
  }) 
  .catch(function (error) {
   console.log(error);
 })
}


function onRestartInstance(){
  setLoader(true);
  restartInstance({id: instanceId?.id, token: token}).then((response) => {    
    if (response.data.status === true) {   
      window.dispatchEvent(new Event("storage"));
      handleNotificationCall("success", response.data.message);  
      setInstanceId();
      
     } else{
      handleNotificationCall("error", response.data.message);  

    }
    handleRemoveClose();
    setLoader(false);
  }) 
  .catch(function (error) {
   console.log(error);
 })
}


function onResetInstance(){
  setLoader(true);
  resetInstance({id: instanceId?.id, token: token}).then((response) => {    
    if (response.data.status === true) {  
     
      window.location.reload();
      
     } else{
      handleNotificationCall("error", response.data.message);  

    }
    handleRemoveClose();
    setLoader(false);
  }) 
  .catch(function (error) {
   console.log(error);
 })
}




// function onAddUserTransaction(plan_id){
//   setLoader(true);

//   addUserTransaction({plan_id: plan_id, token:token}).then((response) => {    
   
//     if (response.data.status === true) {    
//    handlePayClose();
//    setLoader(false);
//       const options = {
//           key: process.env.REACT_APP_RAZOR_KEY,
//           currency: "INR",
//           order_id: response.data.data.id,
//           handler: (res) => {
//             setLoader(true);
    
//             getPaymentSuccess(res).then((result) => {    
            
//                     if (result.data.status === true) {    
//                       handleNotificationCall("success", result.data.message);  
//                       window.location.reload();
//                      } else {
//                       handleNotificationCall("error", "Something went wrong!!");  
//                      }
//                      setLoader(false); 
//                   }) 
//                   .catch(function (error) {
//                    console.log(error);
//                  })
//           },
//           theme: {
//               color: "#3399cc",
//           },
//       };

//       if (Razorpay) {
//           const rzpay = new Razorpay(options);
//           rzpay.open();
//       }  
      
       
//      } else {
//       setLoader(false); 
//       handleNotificationCall("error", "Something went wrong!!");  
//      }
//   }) 
//   .catch(function (error) {
//    console.log(error);
//  })
// }



 
 

    return (
    <>
         
            <Grid container direction='row' spacing={2}  className={classes.profileHead}>
                
                <Grid item xs={12} sm={8} md={8} lg={8}    className={classes.profileHeadLeft} >
                <Grid container spacing={1}  className={classes.profileBody }>
                        <Grid item xs={2} sm={2} md={2} lg={1}  >
                            
                                <Avatar alt="Image"   className={classes.profileAvatar }  sizes='large'
                                src={ instanceId? instanceId?.profileImage : user?.firstname } />    
                        </Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9} className={classes.userDetails} >
                       
                             
                            <Typography variant='h6'>  {  instanceId?.name? instanceId?.name : "Instance" }</Typography>
                            <Typography variant='body1'  > {instanceId?.mobile }</Typography>
                             {/* <Typography variant='body1'> Valid till { moment(instanceId?.validity_end_date).tz(moment.tz.guess()).format("DD-MM-YYYY")}</Typography>  */}
                            {/* <Typography variant='body1'> { moment().tz(moment.tz.guess()).format("YYYY-MM-DD") >  moment(instanceId?.validity_end_date).tz(moment.tz.guess()).format("YYYY-MM-DD")  ? <div className={globalClasses.Blink}>Your Instance Validity Has Expired Please Recharge!  </div> : " "  } </Typography> */}
                            <Grid item xs={4}  >
                            
                          {/* {reset === false?   <Button
                          
                            variant="contained"
                            size="small"
                            color='primary'
                            onClick={(e)=>{  
                              getAllPlanList();
                            }}
                            disabled={instanceId?.name? moment().tz(moment.tz.guess()).format("YYYY-MM-DD") >  moment(instanceId?.validity_end_date).tz(moment.tz.guess()).format("YYYY-MM-DD") ? true: false : true}

                            >
                            Pay Now
                        </Button> :""  } */}
                            </Grid>
                          
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4} className={classes.profileHeadRight}  >
                {user?.role_code==="ADMIN" &&<div className={classes.bannerBtn}>

                     {reset === false?
                     <>
                        <Button
                            startIcon={<RiRestartFill style={{ color: "#fff"}} />}
                            variant="contained"
                            size="small"
                            color='primary'
                     
                            onClick={(e)=>{
                              setRestart(true);
                              handleRemoveOpen();
                            }}
                      //   disabled={instanceId?.name? moment().tz(moment.tz.guess()).format("YYYY-MM-DD") >  moment(instanceId?.validity_end_date).tz(moment.tz.guess()).format("YYYY-MM-DD")? true: false : true }
                            >
                            Restart  
                        </Button>
                        <Button
                            startIcon={<AiOutlineClear style={{ color: "#fff"}} />}
                            variant="contained"
                            size="small"
                            color='secondary'
                         //   disabled={instanceId?.name? moment().tz(moment.tz.guess()).format("YYYY-MM-DD") >  moment(instanceId?.validity_end_date).tz(moment.tz.guess()).format("YYYY-MM-DD") ? true: false : true}
                            onClick={(e)=>{
                              setRestart(false);
                              handleRemoveOpen();
                            }}
                            >
                            Clear
                            
                        </Button>
                        </>  :
                        <Button
                        startIcon={<RiRestartFill style={{ color: "#fff"}} />}
                        variant="contained"
                        size="small"
                        color='primary'
                  
                        onClick={(e)=>{
                          setRestart(null);
                          handleRemoveOpen();
                        }}
                         >
                        Reset  
                    </Button>
                        }
                    </div> }
                        
                </Grid>

            </Grid>
           
        
        
      <AppBar position="static"  className={globalClasses.marginTop15}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className={classes.tabList}
          TabIndicatorProps={{
            // style: {
            //   display: "none"
            // }
          }}
        >
          {user?.role_code==="ADMIN" && <Tab label="Authentication" icon={<FcPrivacy style={{ fontSize: 30}} />} iconPosition="start" {...a11yProps(0)}   /> }
          {reset === false && parseInt(instanceId?.is_valid) === 1 &&   <Tab label="Template" icon={<FcNews style={{ fontSize: 30}} />} {...a11yProps(1)}  />   }
          {reset === false && parseInt(instanceId?.is_valid) === 1 &&   <Tab label="Send Message" icon={<FcVoicePresentation style={{ fontSize: 30}} />} {...a11yProps(2)}     />}
          {reset === false && parseInt(instanceId?.is_valid) === 1 &&   <Tab label="Message" icon={<WhatsAppIcon style={{ color: "green"}} />} {...a11yProps(3)}   />}
          {reset === false && parseInt(instanceId?.is_valid) === 1 && user?.role_code==="ADMIN" &&  <Tab label="Settings" icon={<FcSettings style={{ fontSize: 30 }} />} {...a11yProps(3)}     />}
          
        </Tabs>
      </AppBar>
     
      {user?.role_code==="ADMIN" && <TabPanel value={value} index={0}>
        <Authentication   setInstanceId={setInstanceId} instanceId={instanceId} />
      </TabPanel> }
      <TabPanel value={value}  index={user?.role_code==="ADMIN"? 1: 0}>
        <Template instanceId={instanceId}  />
      </TabPanel>
      <TabPanel value={value} index={user?.role_code==="ADMIN"?2:1}>
     
        <SendMessage instanceId={instanceId} mobile={mobile} setValue={setValue} />
       
      </TabPanel>

      <TabPanel value={value} index={user?.role_code==="ADMIN"?3:2} className={globalClasses.padding10}>
        <Message   />
      </TabPanel>

      {user?.role_code==="ADMIN" &&  <TabPanel value={value} index={user?.role_code==="ADMIN"?4:3} >
        <Settings  instanceId={instanceId} setInstanceId={setInstanceId}  />
      </TabPanel> }

      <Grid
          container
          direction="row"
          spacing={2}
          className={classes.drawerFooter}
        >
        
          {/* <Dialog onClose={handlePayClose} aria-labelledby="dialog-title" open={payOpen} width='md' PaperProps={{ style: {  width: '100%' } }}>
           <DialogContent style={{ padding: "0px"}} > 
        <Grid className={globalClasses.center +" "+globalClasses.dialogTitle}  >
 
<Grid
            container
            direction="row"
            spacing={2}
            
          >
                <Grid item xs={12} className={globalClasses.dialogHeader}>
            <Typography  variant="subtitle1"> Pricing</Typography>

          
              <CloseIcon
                className={globalClasses.closeBtn}
                size="14px"
                onClick={(e)=>[
                  handlePayClose()
                ]}
              />
            </Grid>
          </Grid>
      </Grid>
     
      <Container   component="main" style={{ paddingBottom: "20px"}}>
        <Grid container spacing={2} alignItems="center" justifyContent='center' className={globalClasses.gap10 + " " +globalClasses.marginTop15}>
          {planList.map((item) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={item.validity} xs={5}  className={classes.card}>
              <Card>
                <CardHeader
                  title={item.title}
                  subheader={item.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                 
                  className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                  <div className={classes.cardPricing}>
                    <Typography component="h6" variant="h6" color="textPrimary">
                    ₹ {item.price} 
                    </Typography>
                    
                  </div>
                   
                  
                      <Typography  variant="body1"    >
                      {item.description}
                      </Typography>
                    
                  
                </CardContent>
                <CardActions>
                  <Button fullWidth  variant="contained"   size="small" color="primary"
                  
                  onClick={(e)=>{
                onAddUserTransaction(item.id);
                  }}>
                 Pay Now
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

     

             </DialogContent>

</Dialog> */}


 

<Dialog onClose={handleRemoveClose} aria-labelledby="dialog-title" open={removeOpen} width='md' PaperProps={{ style: {  width: '100%' } }}>

{/* <DialogTitle >Confirmation</DialogTitle>  */}
<DialogContent style={{ padding: "0px"}} > 
         <Grid className={globalClasses.center +" "+globalClasses.dialogTitle}  >
 
<Grid    container  direction="row"     spacing={2}  >
                <Grid item xs={12} className={globalClasses.dialogHeader}>
            <Typography  variant="subtitle1"> {restart===true? "Are you sure you want to restart?" 
                                            : restart===false? "Are you sure you want to clear?" 
                                            : "Are you sure you want to reset?" } </Typography>

          
              <CloseIcon
                className={globalClasses.closeBtn}
                size="14px"
                onClick={(e)=>[
                  handleRemoveClose()
                ]}
              />
            </Grid>
          </Grid>
      </Grid>

    
<DialogActions  className={globalClasses.marginTop15}> 
<Button  variant="contained"  color="primary"  size="small"  onClick={(e) => {    
  restart===true?  onRestartInstance() : restart===false? onDeleteInstance() : onResetInstance()
   
  }}>  Yes   </Button> 
<Button variant="contained" size="small" color="secondary" onClick={handleRemoveClose} >No</Button>

</DialogActions>

</DialogContent>

</Dialog>


</Grid>

<div id={ loader === true? "spinner" :""} ></div>
    <div  className={loader === true? "main" :"" }  ></div>
 
    </>
  );
}

