import React, { useState, useEffect } from "react";
import { Grid, Typography, Backdrop, CircularProgress,  Avatar,  Button } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
 
import useStyles from "./styles"; 
 import { ToastContainer, toast } from 'react-toastify';
  import CloseIcon from '@material-ui/icons/Close'; 
import { verifyEmail } from '../../services/user.api';  
 import Lottie from "lottie-react";
import animation from '../../images/chat.json';
 import Notification from "../../components/Notification/Notification";
//import useGlobalStyles from "../../themes/style";
import { FcBusinessman } from "react-icons/fc";
import logo from '../../images/infoaptoTech.jpg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

function Forget(props) { 
  var classes = useStyles();
   const [loader, setLoader] = useState(false);  
   const [view, setView] = useState("");  

  // var globalClasses= useGlobalStyles(); 
  
    
  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress,
          className: classes.notification,
        },
    );
  }

  useEffect(() => {
 
    setLoader(true);
     
    verifyEmail({token: props.match.params.token}).then((response) => {    
     
        
         if (response.data.status === true) { 
            handleNotificationCall("success", response.data.message);  
            setView("success");

         } else{ 
            handleNotificationCall("error", response.data.message);    
            setView("error");
           
         }

         setLoader(false);
         
       }) 
       .catch(function (error) {
        console.log(error);
      })    
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

  
 

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
      <Lottie animationData={animation} loop={true}  className={classes.fullImg} />
        <Typography className={classes.logotypeText}>  </Typography>
      </div>
     
      <div className={classes.formContainer}>
        <div className={classes.form}>
        
        <Avatar  className={classes.large} >
            <FcBusinessman style={{ fontSize: "70px"}} />
          </Avatar>
          <Typography  className={classes.loginText} > <img src={logo} alt="bg" style={{ width: "100%"}}  />  </Typography>
               <React.Fragment>
          { view === "success"? 
          <> 
          <CheckCircleIcon  style={{ fontSize: 45, color:"green" }} />
          <Typography  className={classes.loginText} > {"Verified"}  </Typography> </>
          :
          <> 
          <CancelIcon  style={{ fontSize: 45, color:"red" }} />
          <Typography  className={classes.loginText} >{"Not Verified"}   </Typography> </>
          }
           <br/>
           <Button
          
          variant="contained"
          color="primary"
          component={Link}
          to="/login"
          size="small" 
        >
          Back to Login
        </Button>

                </React.Fragment>
        
        </div>
        
      </div>

  <ToastContainer
  closeButton={
  <CloseButton className={classes.notificationCloseButton} />
  }
  closeOnClick={false}
  hideProgressBar={true}
  />


<Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </Grid> 
  );
}

export default withRouter(Forget);
function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
} 