import React, { useState, useEffect, useReducer} from "react";
import MUIDataTable from "mui-datatables";
import {
  Grid, 
  Button,
   Backdrop,
   Typography,
  CircularProgress,
  TablePagination,
  Dialog, DialogContent,   DialogContentText, DialogActions,

} from "@material-ui/core";
 import PageTitle from "../../components/PageTitle"; 
 import CloseIcon from "@material-ui/icons/Close";
 import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

 import useStyles from "../../themes/style.js";
import useMediaQuery from '@material-ui/core/useMediaQuery';
 import { getNonWhatsAppContacts, deleteNoWhatsAppContact  } from '../../services/contact.api'; 
 import Notification from "../../components/Notification/Notification";
 import { toast } from "react-toastify";

 
export default function NotWhatsapp(props) {
  const classes = useStyles();
  const mobileQuery = useMediaQuery('(max-width:600px)');  
  //var moment = require('moment-timezone');

   const [contactData, setContactData] = useState([]);
   var token = localStorage.getItem("token");
   const user = JSON.parse(localStorage.getItem("user"));

    const [page, setPage] = useState(0); 
  const [currerntPage, setCurrerntPage] = useState(1);
 
   const [loader, setLoader] = useState(false);
   const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

   function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress,
          className: classes.notification,
        },
    );
  }

  
 const [count, setCount] = useState(0);


 var selectId = [];

 const handleRowSelectionChange = (allRowsSelected) => {
   const id = allRowsSelected?.map((rowIndex) => contactData[rowIndex.dataIndex].to_number);
   selectId = id;   
  };


const [selectedId, setselectedId] = React.useState(selectId);


 const [removeOpen, setRemoveOpen] = React.useState(false);

 const handleRemoveOpen = () => {
  setRemoveOpen(true);
};

const handleRemoveClose = () => {
  setRemoveOpen(false);
};

function removeContact(){

  deleteNoWhatsAppContact({phone_number: selectedId, token: token}).then((response) => {    
    if (response?.data?.status === true) {    
      forceUpdate();
       handleNotificationCall("success", response.data.message);  
      
       setLoader(false);
       handleRemoveClose();

        } else{
          handleNotificationCall("error", response.data.message);  
        }
      })
        .catch(function (error) {
         console.log(error);
       })
}

 const HeaderElements = () => <>Total : {count}</>;



 const SelectElements = () => <Grid className={classes.gap10}> 
<Button
 variant="contained"
 color="secondary"
 size="small"
 startIcon={<DeleteForeverIcon />}
 onClick={() =>{
  setselectedId(selectId);
  handleRemoveOpen();
 
 }}
>
Remove Contact
</Button>
</Grid>;

   const handleChangePage = (event, newPage) => {
    setLoader(true);
   

    getNonWhatsAppContacts({page: newPage + 1, role_code: user?.role_code, token:token}).then((response) => {    
      if (response.data.status === true) { 
        
        setContactData(response?.data?.data);
         setCount(response?.data?.count);
            
      }  else{
        setCount(0);
       }
     
       setPage(newPage);
       setCurrerntPage(newPage + 1);
      setLoader(false);
    }) 
    .catch(function (error) {
     console.log(error);
   })

  };

 
 
  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
       
      getNonWhatsAppContacts({page:1, role_code: user?.role_code, token: token}).then((response) => {    
         if (response?.data?.status === true) {
           
          setContactData(response.data.data);
         setCount(response.data.count);
           
 
         } else{
          setCount(0);
         }
         setPage(0);
         setCurrerntPage(1);
         setLoader(false);
       })
       .catch(function (error) {
        console.log(error);
      })
 
    };
    fetchData();
       
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reducerValue]);
 
 
   var table_data = {};

   var columns_data = [];
 
   if (user?.role_code === "ADMIN") {
    columns_data = [
      {
        name: "S.No",
      },
      {
        name: "Name",
      },

      {
        name: "Email",
      },

      {
        name: "Mobile",
      },
      {
        name: "Client Name",
      },
      {
        name: "Created By",
      },
      
    ];
 
   table_data = contactData?.map((item, index) => {
    return [
    currerntPage !== 0 ? 10 * currerntPage - 10 + index + 1   : index + 1 , 
     item.name? item.name:"-" ,
     item.email? item.email:"-",
     item.to_number,
     item?.client_name,
     item?.createdby,
     
   ];
 })
 
 } else{
  columns_data = [
    {
      name: "S.No",
    },
    {
      name: "Name",
    },

    {
      name: "Email",
    },

    {
      name: "Mobile",
    },
    {
      name: "Client Name",
    },
    
  ];
 
   table_data = contactData?.map((item, index) => {
    return [
    currerntPage !== 0 ? 10 * currerntPage - 10 + index + 1   : index + 1 , 
     item.name? item.name:"-" ,
     item.email? item.email:"-",
     item.to_number,
     item?.client_name,
     
   ];
 })
 
 }


  return (
    <>
      <Grid container direction="row" spacing={2} className={classes.heading}>
        <Grid item xs={9} sm={7} md={8} lg={6}>
         
          <PageTitle title="Not Whatsapp Number" />
        </Grid>

        <Grid item xs={3} sm={5} md={4} lg={6} className={classes.drawerClose}>
      
         
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
        <MUIDataTable
            options={{
             
              onRowSelectionChange: (currentRowsSelected, allRowsSelected) => handleRowSelectionChange(allRowsSelected),
              pagination: false,
              search: false,
              download: false,
              print: false,
              viewColumns: true,
              filter: false,
              filterType: "dropdown",
              responsive: mobileQuery===true? 'vertical' : 'standard',
              customToolbarSelect: () => <SelectElements />,
         customToolbar: () => <HeaderElements />,
              textLabels: {
                body: {
                  noMatch: 'Oops! Matching record could not be found',
                }
              }
            }}
            columns={columns_data}
            data={table_data}
          />

          <Grid container spacing={2} className={classes.pagination}>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={count}
              rowsPerPage={10}
              page={page}
              onChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
      </Grid>

 

      <Dialog onClose={handleRemoveClose} aria-labelledby="dialog-title" open={removeOpen} width='md' PaperProps={{ style: {  width: '100%' } }}>
 <DialogContent style={{ padding: "0px"}} > 
<DialogContentText>  
   
<Grid className={classes.center +" "+classes.dialogTitle}  >
 
 <Grid container direction="row" spacing={2}   >
      <Grid item xs={12}  className={classes.dialogHeader}>
        <Typography  variant="subtitle1"> Are you sure, want to delete selected contact?</Typography>
 
               <CloseIcon   className={classes.closeBtn}  size="14px"
                 onClick={(e)=>[
                  handleRemoveClose()
                 ]}
               />
             </Grid>
           </Grid>
       </Grid>

  
 
</DialogContentText> 
<DialogActions> 
<Button  variant="contained"  color="primary"  size="small"  onClick={(e) => {    
  removeContact();
  }}>  Yes   </Button> 
<Button variant="contained" size="small" color="secondary" onClick={handleRemoveClose} >No</Button>

</DialogActions>
</DialogContent>
</Dialog>


      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}