import React, { useState, useEffect, useReducer } from 'react'
import {
  Grid,
  Button,
  InputLabel, 
  List,
  Box,
  SwipeableDrawer, 
  FormControl,
  Typography, 
  Backdrop,
  Tooltip,
  CircularProgress, 
  TextField,
   Switch
} from "@material-ui/core";
import useStyles from "./style";
 import globalStyles from "../../themes/style.js"; 
  import MUIDataTable from "mui-datatables";
 import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import PageTitle from "../../components/PageTitle/PageTitle";
 import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification"; 
import { Visibility as ViewIcon, Close as CloseIcon, } from "@material-ui/icons";
import { viewAllReplyMessage, addReplyMessage, viewReplyMessage, changeMessageToPrimary, editReplyMessage } from '../../services/instance.api'; 
  import useMediaQuery from '@material-ui/core/useMediaQuery'; 
  import { useFormik } from 'formik';
  import { replySchema } from "../../components/Yup/Yup";
   import AddCircleIcon from "@material-ui/icons/AddCircle";
   import EditIcon from "@material-ui/icons/Edit";

 import "react-toastify/dist/ReactToastify.css";
 
function Reply(props) {
 
   const mobileQuery = useMediaQuery('(max-width:600px)');   
   const classes = useStyles(); 
   const globalClasses = globalStyles();
 
  var moment = require('moment-timezone');
  const token = localStorage.getItem("token");

  const [loader, setLoader] = useState(false); 
  const [replyData, setReplyData] = useState([]); 

  const [dataList, setDataList] = useState("ADD"); 

  const [replyView, setReplyView] = useState({
    data:{}
   });

  const [state, setState] = useState({ 
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {  
    setState({ ...state, [anchor]: open });
  }; 
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress, 
          className: classes.notification,
        },
    );
  }


  const onReplyAdd = useFormik({
    initialValues: {  
      message: '', 
    },
     validationSchema: replySchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {  
     

      setLoader(true);
      addReplyMessage( {message: values.message, token:token}).then((response) => {    

       if (response.data.status === true) { 
        handleNotificationCall("success", response.data.message);  
        forceUpdate(); 
        resetForm();
        setState({ ...state, right: false }); 
       } else {  
        handleNotificationCall("error", response.data.message);  
       }
       setSubmitting(false); 
       setLoader(false);
     }) 
     .catch(function (error) {
      console.log(error);
    })
    setLoader(false);
    },
  }); 


  

  const onReplyEdit = useFormik({
    initialValues: {  
      id:'',
      message: '', 
    },
     validationSchema: replySchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {  
     

      setLoader(true);
      editReplyMessage({id: onReplyEdit.values.id, message: values.message, token:token}).then((response) => {    

       if (response.data.status === true) { 
        handleNotificationCall("success", response.data.message);  
        forceUpdate(); 
        resetForm();
        setState({ ...state, right: false }); 
       } else {  
        handleNotificationCall("error", response.data.message);  
       }
       setSubmitting(false); 
       setLoader(false);
     }) 
     .catch(function (error) {
      console.log(error);
    })
    setLoader(false);
    },
  }); 

  const list = (anchor) =>
  dataList === "ADD" ? ( 
    <Box sx={{ width: "100%" }} role="presentation">
    <List>
      <Card className={classes.root}>
        <CardHeader>
          <Grid
            container
            direction="row"
            spacing={1}
            className={globalClasses.drawerHeader}
          >
            <Typography variant="subtitle1"> Add Auto Reply</Typography>

            <Grid className={globalClasses.drawerClose}>
              <CloseIcon
                className={globalClasses.closeBtn}
                size="14px"
                onClick={toggleDrawer(anchor, false)}
              />
            </Grid>
          </Grid>
        </CardHeader>

       
     <form onSubmit={onReplyAdd.handleSubmit}  >

    <CardContent className={globalClasses.drawerContent}>
        <Grid container direction="row" spacing={2}>
        

          <Grid item xs={12}>
            <InputLabel shrink htmlFor="message"> Message </InputLabel>

            <FormControl className={classes.margin}>
              <TextField
               multiline
                rows={3} 
                classes={{ root: globalClasses.customTextField }}
                InputProps={{ disableUnderline: true }}
                size="small"
                placeholder="Enter message"
                type="text"
                name="message" 
                value={onReplyAdd.values.message}
                onChange={onReplyAdd.handleChange}
                error={onReplyAdd.touched.message && Boolean(onReplyAdd.errors.message)}
                helperText={onReplyAdd.touched.message && onReplyAdd.errors.message} 
              />

             
            </FormControl>
          </Grid>
      
     
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          spacing={2}
          className={globalClasses.drawerFooter}
        >
        

          <Button
            variant="contained"
            color="primary"
            size="small"
             type="submit"
             disabled={onReplyAdd.isSubmitting}
          >
            Save
          </Button>

          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={toggleDrawer(anchor, false)}
          >
            
            Close
          </Button>
        </Grid>
      </CardActions>
    </form>
    </Card>
        </List>
        </Box>
 
 ) : dataList === "EDIT" ? ( 
  <Box sx={{ width: "100%" }} role="presentation">
  <List>
    <Card className={classes.root}>
      <CardHeader>
        <Grid
          container
          direction="row"
          spacing={1}
          className={globalClasses.drawerHeader}
        >
          <Typography variant="subtitle1"> Edit Auto Reply</Typography>

          <Grid className={globalClasses.drawerClose}>
            <CloseIcon
              className={globalClasses.closeBtn}
              size="14px"
              onClick={toggleDrawer(anchor, false)}
            />
          </Grid>
        </Grid>
      </CardHeader>

     
   <form onSubmit={onReplyEdit.handleSubmit}  >

  <CardContent className={globalClasses.drawerContent}>
      <Grid container direction="row" spacing={2}>
      

        <Grid item xs={12}>
          <InputLabel shrink htmlFor="message"> Message </InputLabel>

          <FormControl className={classes.margin}>
            <TextField
             multiline
              rows={3} 
              classes={{ root: globalClasses.customTextField }}
              InputProps={{ disableUnderline: true }}
              size="small"
              placeholder="Enter message"
              type="text"
              name="message" 
              value={onReplyEdit.values.message}
              onChange={onReplyEdit.handleChange}
              error={onReplyEdit.touched.message && Boolean(onReplyEdit.errors.message)}
              helperText={onReplyEdit.touched.message && onReplyEdit.errors.message} 
            />

           
          </FormControl>
        </Grid>
    
   
      </Grid>
    </CardContent>
    <CardActions>
      <Grid
        container
        direction="row"
        spacing={2}
        className={globalClasses.drawerFooter}
      >
      

        <Button
          variant="contained"
          color="primary"
          size="small"
           type="submit"
           disabled={onReplyEdit.isSubmitting}
        >
          Save
        </Button>

        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={toggleDrawer(anchor, false)}
        >
          
          Close
        </Button>
      </Grid>
    </CardActions>
  </form>
  </Card>
      </List>
      </Box>

) : (
 <>
 <Box sx={{ width: "100%" }} role="presentation">
   <List>
     <Card className={classes.root}>
       <CardHeader>
         <Grid
           container
           direction="row"
           spacing={1}
           className={globalClasses.drawerHeader}
         >
           <Typography variant="subtitle1"> View Reply Message</Typography>

           <Grid className={globalClasses.drawerClose}>
             <CloseIcon
               className={globalClasses.closeBtn}
               size="14px"
               onClick={toggleDrawer(anchor, false)}
             />
           </Grid>
         </Grid>
       </CardHeader>

       <CardContent className={globalClasses.drawerContent}>
         <Grid container direction="row" spacing={2}>
    

           <Grid item xs={12} sm={6} md={6} lg={6}>    <Typography className={classes.boldtext}>   Message  </Typography>     </Grid>
           <Grid item xs={12} sm={6} md={6} lg={6}>   {replyView.data?.message}    </Grid> 

           <Grid item xs={12} sm={6} md={6} lg={6}> <Typography className={classes.boldtext}> Posted Date   </Typography> </Grid>
           <Grid item xs={12} sm={6} md={6} lg={6}> {replyView.data?.created? moment(replyView.data?.created).format("DD-MM-YYYY") :""}  </Grid> 
        
       
         </Grid>
       </CardContent>
       <CardActions>
         <Grid
           container
           direction="row"
           spacing={2}
           className={globalClasses.drawerFooter}
         >
           <Button
             variant="contained"
             size="small"
             color="secondary"
             onClick={toggleDrawer(anchor, false)}
           >
             
             Close
           </Button>
         </Grid>
       </CardActions>
     </Card>
   </List>
 </Box>
</>
    );
 

useEffect(() => {
  setLoader(true);

 
  const fetchData = async () => {
     
    viewAllReplyMessage({token: token}).then((response) => {    
        if (response.data.status === true) {  
       

        setReplyData(response.data.data); 
       }   
       setLoader(false);
     }) 
     .catch(function (error) {
      console.log(error);
    })

  };
  fetchData();
     
// eslint-disable-next-line react-hooks/exhaustive-deps
 }, [reducerValue]);



 function handleStatus(id) { 
  setLoader(true); 
  changeMessageToPrimary({id: id, token: token,}).then((response) => {    
    if (response.data.status === true) {  
          handleNotificationCall("success", response.data.message);
          forceUpdate();
        }  else{
          handleNotificationCall("error", response.data.message);
        }
      })
        .catch(function (error) {
         console.log(error);
       })
  
  setLoader(false); 

}

 function handleShow(values, name) {
  setLoader(true);
 
  if (name === "EDIT") {
    setDataList("EDIT");
  } else {
    setDataList("VIEW");
  } 

  viewReplyMessage({id: values, token: token}).then((response) => {    
    if (response.data.status === true) {   
    
      setReplyView({
        ...replyView,
        data: response.data.data
      });  

      onReplyEdit.setValues(response.data.data);  

       setState({ ...state, right: true });
      setLoader(false);
        } 
      }) 
        .catch(function (error) {
         console.log(error);
       })


}

  return (
    <>
       <Grid container direction="row" spacing={2} className={classes.heading}>
        <Grid item xs={9} sm={7} md={8} lg={6}>
          
          <PageTitle title="Auto Reply" />
        </Grid>

        <Grid item xs={3} sm={5} md={4} lg={6} className={globalClasses.drawerClose}>
        <div className={globalClasses.lgButton}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              startIcon={<AddCircleIcon />}
              className={classes.margin}
              onClick={(e) => {
                onReplyAdd.resetForm(); 

                setDataList("ADD"); 
                setState({ ...state, right: true });
              }}
            >
              Add New Auto Reply
            </Button>
          </div>

          <div className={globalClasses.smButton}>
            <Button
              variant="contained"
              size="small"
              startIcon={<AddCircleIcon />}
              className={classes.margin}
              color="primary"
              onClick={(e) => {
                onReplyAdd.resetForm(); 

                setDataList("ADD");
              
                setState({ ...state, right: true });
              }}
            >
              
              Add
            </Button>
          </div>
          
        </Grid>
      </Grid>


      <Grid container spacing={2}>
        <Grid item xs={12}>
 
          
          <MUIDataTable
            options={{
              pagination: true,
              sort: false,
              selectableRows: "none",
              search: false,
              filter: false,
              print: false,
              download: false,
              responsive: mobileQuery===true? 'vertical' : 'standard',
           //   customToolbar: () => <HeaderElements />,
              textLabels: {
                body: {
                  noMatch: 'Oops! Matching record could not be found',
                }
              }
            }}
            columns={[
              {
                name: "S.No",
              },
              
              {
                name: "Message",
              },
              {
                name: "Primary",
              },
 
              {
                name: "Posted Date",
              },
              {
                name: "Actions",
              },
              
            ]}
            data={replyData?.map((item, index) => {
               return [
                index + 1, 
               
                item.message, 
                <Switch
                checked={parseInt(item.isPrimary) === 1 ? true: false }
                onChange={(e) => {
                   handleStatus(item.id);
                }}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }} />,
                item?.created? moment(item?.created).tz(moment.tz.guess()).format('DD-MM-YYYY') :"",
                <>
                <Grid container className={globalClasses.space}>
                  <Grid item xs className={globalClasses.toolAlign}>
                  <Tooltip
                        title="Edit Reply"
                        placement="bottom"
                        aria-label="edit"
                      >
                        <EditIcon
                          className={globalClasses.toolIcon}
                          onClick={(e) => {
                           handleShow(item.id, "EDIT");
                          }}
                        />
                      </Tooltip>
                    <Tooltip
                      title="View Reply"
                      placement="bottom"
                      aria-label="view"
                    >
                      <ViewIcon
                        className={globalClasses.toolIcon}
                        onClick={(e) => {
                          handleShow(item.id, "VIEW");
                        }}
                      />
                    </Tooltip> 
                    
                  </Grid>
                </Grid>
              </>,
              ];
            })}
          />


<SwipeableDrawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
            classes={{ paper: globalClasses.drawer }}
            disableSwipeToOpen={false}
          >
  
            {  list("right")} 

          </SwipeableDrawer>
          {/* <Grid container spacing={2} className={classes.pagination}>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={count}
              rowsPerPage={10}
              page={page}
              onChangePage={handleChangePage}
            />
          </Grid> */}
        </Grid>
      </Grid>


<Backdrop className={globalClasses.backdrop} open={loader}>
<CircularProgress color="inherit" />
</Backdrop>
</>
  )
}

export default Reply