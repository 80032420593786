import React, { useState, useEffect, useReducer, useRef } from "react";
import MUIDataTable from "mui-datatables";
import {
  Grid,
  Button,
  Link, 
  Dialog,
  DialogContent,
  Typography,
   Backdrop,
  CircularProgress, 
  TextField,
  TablePagination
} from "@material-ui/core";
 import PageTitle from "../../components/PageTitle/PageTitle"; 
import CloseIcon from "@material-ui/icons/Close";
 import useStyles from "../admin/style.js";
import useGlobalStyles from "../../themes/style";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { viewUserTransaction, viewAllTransactions } from '../../services/transaction.api';
import PDF from "../../components/Pdf/Pdf";
import "react-toastify/dist/ReactToastify.css";


export default function Transaction(props) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  var moment = require('moment-timezone');

   const mobileQuery = useMediaQuery('(max-width:600px)');   
   const [transactionData, setTransactionData] = useState([]); 
  const [transactionView, setTransactionView] = useState({ 
    data:""
  });  
  const token= localStorage.getItem('token');

  const [page, setPage] = useState(0);
 const [currerntPage, setCurrerntPage] = useState(1);
  const [reducerValue, forceUpdate ] = useReducer((x) => x + 1, 0);
 
 const [count, setCount] = useState(0);
  const [loader, setLoader] = useState(false);
 
  const filterRef = useRef(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [referral, setReferral] = useState("");

  const HeaderElements = () => <>Total : {count}</>;

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    
    const form = filterRef.current;
    
    viewAllTransactions({
      page: newPage + 1, 
      referredBy: `${form["referral"].value}`,
      fromDate: `${form["fromDate"].value}`,
      toDate: `${form["toDate"].value}`,
      token: token,
    }).then((response) => {    
      if (response.data.status === true) { 
        
        setTransactionData(response.data.data);
           setCount(response.data.count);
           setLoader(false);

      }  else{
        setCount(0);
        setTransactionData([]);
       setLoader(false);
     }
     setPage(newPage);
    setCurrerntPage(newPage + 1);
    }) 
    .catch(function (error) {
     console.log(error);
   })

  };

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
       
      viewAllTransactions({  page: 1, token: token, }).then((response) => {    
         if (response.data.status === true) { 
          setCount(response.data.count);
           setTransactionData(response.data.data);
           setLoader(false);
         }  else{
          setCount(0);
          setTransactionData([]);
         setLoader(false);
       }
        
       setPage(0);
       setCurrerntPage(1);
       }) 
       .catch(function (error) {
        console.log(error);
      })
 
    };
    fetchData();
       
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reducerValue]);
  

  const [modalOpen, setModalOpen] = React.useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleClickOpen = (values) => {  
    setTransactionView({
      ...transactionView,
      data: ""
      });

    handleShow(values); 
  };
 
 
  function handleShow(values){
     setLoader(true);
    

    viewUserTransaction({id: values.id, token: token}).then((response) => {    
      if (response.data.status === true) { 
 
          if(response.data.data){
            setModalOpen(true);
          }
          setTransactionView({
            ...transactionView,
            data: response.data.data 
            });
 
          } 
          handleClose();
          setLoader(false);
        }) 
          .catch(function (error) {
           console.log(error);
         })
    
  }
  const handleReferralChange= (event) => {
    setReferral(filterRef.current.fromDate.value);
  };
   
  const handleFromDateChange = (event) => {
    setFromDate(filterRef.current.fromDate.value);
  };

  const handleToDateChange = (event) => {
    setToDate(filterRef.current.toDate.value);
  };

  function getFilterData() {
    setLoader(true);
     
    const form = filterRef.current;
 

    viewAllTransactions({
      page: 1, 
      referredBy: `${form["referral"].value}`,
      fromDate: `${form["fromDate"].value}`,
      toDate: `${form["toDate"].value}`,
      token: token,
    }).then((response) => {    
        if (response.data.status === true) { 
       
          setLoader(false);
          setTransactionData(response.data.data);
         
            }  else{
              setCount(0);
              setTransactionData([]);
             setLoader(false);
           }
            handleClose();
            setPage(0);
       setCurrerntPage(1);
          }) 
            .catch(function (error) {
             console.log(error);
           })
           
  }
 
const resetForm = (e) => {
    filterRef.current.reset(); 
    forceUpdate();
  };

 

  return (
    <>
      <Grid container direction="row" spacing={2} className={classes.heading}>
        <Grid item xs={9} sm={7} md={8} lg={6}>
          
          <PageTitle title="Transaction" />
        </Grid>

        <Grid item xs={3} sm={5} md={4} lg={6} className={classes.drawerClose}>
          
        </Grid>
      </Grid>



      <form
        ref={filterRef}
        onSubmit={(e) => {
          e.preventDefault();
          getFilterData();
        }}
      >
        <Grid container spacing={2} className={globalClasses.filterGap}>
         
        <TextField
            name="referral"
           label="Referral Code"
            InputLabelProps={{ shrink: true }}
            classes={{ root: globalClasses.filterFullWidth }}
            defaultValue={referral}
            onChange={handleReferralChange}
          />

          <TextField
            name="fromDate"
           label="From"
            InputLabelProps={{ shrink: true }}
            classes={{ root: globalClasses.filterFullWidth }}
            type="date"
            defaultValue={fromDate}
            onChange={handleFromDateChange}
          />

          <TextField
            name="toDate"
           label="To"
            InputLabelProps={{ shrink: true }}
            classes={{ root: globalClasses.filterFullWidth }}
            type="date"
            defaultValue={toDate}
            onChange={handleToDateChange}
          />

          <div className={globalClasses.buttons}>
            <Button
              variant="contained"
              size="small"
              color="primary" 
              type="submit"
            >
              Search
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => resetForm()}
            >
              Reset
            </Button>
          </div>
        </Grid>
      </form>

      <Grid container spacing={2}>
        <Grid item xs={12}>
        <MUIDataTable
            title={""}
            options={{
              textLabels: {
                body: {
                  noMatch: 'Oops! Matching record could not be found',
                }
              },
              pagination: false,
              sort: false,
              selectableRows: "none",
              search: false,
              filter: false,
              print: false,
              download: false,
           customToolbar: () => <HeaderElements />,
              responsive: mobileQuery===true? 'vertical' : 'standard',
            }}
            columns={[
              {
                name: "S.No",
              },
              
              {
                name: "Invoice Number",
              },
           
              {
                name: "Amount",
              },
              {
                name: "Status",
              },
              {
                name: "Referred By",
              },
              {
                name: "Invoice Date",
              },
            ]}
            data={transactionData?.map((item, index) => {
              return [
                currerntPage !== 0 ? 10 * currerntPage - 10 + index + 1   : index + 1 , 
                <Grid container className={globalClasses.gap10}>
              
             <Link
        component="button"
        variant="body2"
        style={{ textAlign: "center", textDecoration:"underline" }}
        onClick={(e)=>{ 
          handleClickOpen(item);
         }}
      >
      {item?.invoice_number}
      </Link>
              
            </Grid>,
                
                item?.total_amount,
                <Button variant="contained" size="small" style={{ pointerEvents: "none"}} className={ classes.green  }>   {item?.status}  </Button>,  
                item?.referredBy,
                item?.created? moment(item?.created).format("DD-MM-YYYY") :"",
              ];
            })}
          />

          <Grid container spacing={2} className={globalClasses.pagination}>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={count}
              rowsPerPage={10}
              page={page}
              onChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
      </Grid>


      <Dialog
         aria-labelledby="dialog-title"
         onClose={handleClose}
         open={modalOpen}
         width='lg' PaperProps={{   style: {
        width: "100%",
         //  left:"100%",
          }, }}
         maxWidth="lg"
          
      >
        <DialogContent  className={classes.iframe} >
            
     <Grid className={globalClasses.center +" "+globalClasses.dialogTitle}  >
 
 <Grid    container   direction="row"   spacing={2}   >
                 <Grid item xs={12}  className={classes.dialogHeader}>
             <Typography  variant="subtitle1"> {transactionView?.data?.invoice_number+".pdf"}</Typography>
 
           
               <CloseIcon
                 className={globalClasses.closeBtn}
                 size="14px"
                 onClick={(e)=>[
                  handleClose()
                 ]}
               />
             </Grid>
           </Grid>
       </Grid>
        <PDF transactionView={transactionView} handleClose={handleClose}    />
        
        </DialogContent>
      </Dialog>

      

      <Backdrop className={globalClasses.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

