import { api } from "./api";
 

export const addGroup = async (data, value) => {
  api.defaults.headers.common['Authorization'] = value.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("group/addGroup", {
      name: data.name,
      description : data.description,
    });
    return response;
  };

export const editGroup = async (data, value) => {
  api.defaults.headers.common['Authorization'] = value.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("group/editGroup", {
    id: value.id,
    name: data.name,
    description : data.description,
  });
  return response;
};

export const deleteGroups = async (data) => {
 
  var url= "group/deleteGroups"
  if(data?.role_code === "USER"){
    url= "group/deleteSubUserGroups"
  }  
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post(url, {
    groups: data.groups, 
  });
  return response;
};

export const viewGroup = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("group/viewGroup", {
        id: data.id,
        page: data.page,
    });
    return response;
  };

  export const viewAllGroup = async (data) => {
    var url= "group/viewAllGroups"; 
    if(data?.role_code === "USER"){
      url= "group/viewAllSubUserGroups";
    }  
 
     api.defaults.headers.common['Authorization'] = data.token;
     api.defaults.headers.common['Content-Type']= 'application/json'
    
     try { 
      const response = await api.post(url, {page: data.page}); 
      return response;

    } catch (err) {
       console.error(err.message);
    }
   
  };

  export const groupList = async (data) => {
    var url= "group/groupList"; 
    if(data?.role_code === "USER"){
      url= "group/usergroupList";
    }  

    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    try { 
     const response = await api.post(url, {}); 
     return response;

   } catch (err) {
      console.error(err.message);
   }
  
 };

 