 
import { api } from "./api";
 
 
export const message_rates = async (data) => { 
  
  var url= "analytics/message_rates"; 
  if(data?.role_code === "USER"){
    url= "analytics/message_rates_subuser";
  }  

    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
      const response = await api.post(url, {
        instance_id: data.instance_id,
        fromDate: data.fromDate,
        toDate: data.toDate,
      });
      return response;
    };
  

    export const total_messages = async (data) => {

      var url= "analytics/total_messages"; 
      if(data?.role_code === "USER"){
        url= "analytics/total_messages_subuser";
      }  
        api.defaults.headers.common['Authorization'] = data.token;
        api.defaults.headers.common['Content-Type']= 'application/json'
          const response = await api.post(url, {
            instance_id: data.instance_id,
            fromDate: data.fromDate,
            toDate: data.toDate,
          });
          return response;
        };
      

        
        
          export const message_statistics = async (data) => {

            var url= "analytics/message_statistics"; 
            if(data?.role_code === "USER"){
              url= "analytics/message_statistics_subuser";
            }  

        api.defaults.headers.common['Authorization'] = data.token;
        api.defaults.headers.common['Content-Type']= 'application/json'
          const response = await api.post(url, {
            instance_id: data.instance_id,
            fromDate: data.fromDate,
            toDate: data.toDate,
          });
          return response;
        };
      