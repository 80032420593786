import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import {
  Grid,
  Box,
  Button, 
   Backdrop,
  CircularProgress, 
  TextField,
  FormControl,
  InputLabel,
  TablePagination,
  IconButton,
   Typography,
  List,
  SwipeableDrawer,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Tooltip from "@material-ui/core/Tooltip";

import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
 import PageTitle from "../../components/PageTitle"; 
 import useStyles from "./style.js";
import useGlobalStyles from "../../themes/style";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {  viewAllTags } from '../../services/tags.api'; 
import {  getContactsByTag } from '../../services/contact.api'; 
import CloseIcon from "@material-ui/icons/Close";

import { Autocomplete } from "@material-ui/lab"; 
import { FaUserPlus } from "react-icons/fa";
import parsePhoneNumber from 'libphonenumber-js'
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { useFormik } from 'formik';
import { contactSchema, contactWithoutSchema } from "../../components/Yup/Yup";
import { addContact } from '../../services/contact.api';  
import PhoneInput from "react-phone-input-2";
import parseMobile from 'libphonenumber-js/mobile'

 import "react-toastify/dist/ReactToastify.css";
 
export default function SearchTag(props) {
  const classes = useStyles();
  var moment = require('moment-timezone');

  const globalClasses = useGlobalStyles();
   const mobileQuery = useMediaQuery('(max-width:600px)');   
   const [searchData, setSearchData] = useState([]); 
  

   const [page, setPage] = useState(0);
    const [currerntPage, setCurrerntPage] = useState(1);
 // const [reducerValue, forceUpdate ] = useReducer((x) => x + 1, 0);
  const filterRef = useRef(null); 
  const token =localStorage.getItem("token");  
  const user = JSON.parse(localStorage.getItem("user"));
  
  const [count, setCount] = useState(0);
  const [loader, setLoader] = useState(false);
 
 const HeaderElements = () => <>Total : {count}</>;
 
  const handleChangePage = (event, newPage) => {
    setLoader(true);
    
     
    getContactsByTag({
      page: newPage + 1, 
      tag_id: tagValue?.id, 
      role_code: user?.role_code,
      token: token 
    }).then((response) => {    
      if (response.data.status === true) { 
        
        setSearchData(response.data.data);
           setCount(response.data.count);
           setLoader(false);

      }  else{
        setCount(0);
        setSearchData([]);
       setLoader(false);
     }
     setPage(newPage);
    setCurrerntPage(newPage + 1);
    }) 
    .catch(function (error) {
     console.log(error);
   })

  };

  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress,
          className: classes.notification,
        },
    );
  }

  const [tagData, setTagData] = React.useState([]); 
  const [tagValue, setTagValue] = React.useState(null);
 
  function getFilterData() {
    setLoader(true);
     
   // const form = filterRef.current;
   
    getContactsByTag({
        page: 1, 
        tag_id: tagValue?.id, 
        role_code: user?.role_code,
        token: token 
      }).then((response) => {    
        if (response.data.status === true) { 
       
          setLoader(false);
          setSearchData(response.data.data);
          setCount(response.data.count);
            }  else{
           
              setSearchData([]);
             setLoader(false);
             setCount(0);
           }
             
           
          }) 
            .catch(function (error) {
             console.log(error);
           })
           
  }
 
const resetForm = (e) => {
    filterRef.current.reset();  
    setTagValue(null);
  };

 
  
  useEffect(() => {

    
    viewAllTags({role_code: user?.role_code, token:token}).then((response) => {    
    
    if(response.data.status === true) {  
     setTagData(response.data.data);
     setLoader(false);
    } 
     
   }) 
   .catch(function (error) {
    console.log(error);
  })

   // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ ])

//   useEffect(() => {
//     setLoader(true);

//     const fetchData = async () => {
       
//         getContactsByTag({  page: 1, token: token }).then((response) => {    
//          if (response.data.status === true) { 
//           setCount(response.data.count);
//            setSearchData(response.data.data);
//            setLoader(false);  
//          } else {
//           setCount(0);
//           setSearchData([]);
//          setLoader(false);
//          }
        
        
//        }) 
//        .catch(function (error) {
//         console.log(error);
//       })
 
//     };
//     fetchData();
       
//  // eslint-disable-next-line react-hooks/exhaustive-deps
//    }, [reducerValue]);
  
 


function searchResult (){
  setLoader(true);
     
  // const form = filterRef.current;
  
   getContactsByTag({
       page: 1, 
       tag_id: tagValue?.id, 
       role_code: user?.role_code,
       token: token 
     }).then((response) => {    
       if (response.data.status === true) { 
      
         setLoader(false);
         setSearchData(response.data.data);
         setCount(response.data.count);
           }  else{
            setCount(0);
             setSearchData([]);
            setLoader(false);
          }
            
          
         }) 
           .catch(function (error) {
            console.log(error);
          })
          
}

const [phoneNumber, setPhoneNumber] = React.useState('');
const [countryCode, setCountryCode] = React.useState('');
const [isValid, setIsValid] = useState(true);

const handleChange = (values, allDetails) => {   
  setCountryCode(allDetails.dialCode);
  setPhoneNumber(values);
};

const [state, setState] = useState({
  right: false,
});

const toggleDrawer = (anchor, open) => (event) => {   
  setState({ ...state, [anchor]: open }); 
};

const onContactAdd = useFormik({
  initialValues: {
    name: '',
    email: '',
    mobile:'',
    client_name:''
  },
   validationSchema: phoneNumber === ""? contactSchema: contactWithoutSchema,
   onSubmit: (values, { setSubmitting, resetForm }) => {  
    setLoader(true);
    addContact(values, {mobile:phoneNumber, countryCode:countryCode, token: token}).then((response) => {    
    if (response?.data?.status === true) {
      handleNotificationCall("success", response.data.message);  
     
      resetForm();
      searchResult();
      setState({ ...state, right: false });
     } else {  
      handleNotificationCall("error", response.data.message);  
     }
     setSubmitting(false);
     setLoader(false);
   })
   .catch(function (error) {
    console.log(error);
  })

  },

});  


const list = (anchor) =>
  (

    <Box sx={{ width: "100%" }} role="presentation">
    <List>
      <Card >
        <CardHeader>
          <Grid
            container
            direction="row"
            spacing={1}
            className={globalClasses.drawerHeader}
          >
            <Typography variant="subtitle1"> Add Contact</Typography>
  
            <Grid className={globalClasses.drawerClose}>
              <CloseIcon
                className={globalClasses.closeBtn}
                size="14px"
                onClick={toggleDrawer(anchor, false)}
              />
            </Grid>
          </Grid>
        </CardHeader>
  
       
     <form onSubmit={onContactAdd.handleSubmit}  >
  
    <CardContent className={globalClasses.drawerViewContent}>
        <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
            <InputLabel shrink htmlFor="name">  Name    </InputLabel>
  
            <FormControl className={classes.margin}>
              <TextField
                classes={{ root: globalClasses.customTextField }}
                InputProps={{ disableUnderline: true }}
                size="small"
                placeholder="Enter name"
                type="text"
                name="name" 
                value={onContactAdd.values.name}
                onChange={onContactAdd.handleChange}
                error={onContactAdd.touched.name && Boolean(onContactAdd.errors.name)}
                helperText={onContactAdd.touched.name && onContactAdd.errors.name}
  
              />
  
            </FormControl>
          </Grid>
         
  
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <InputLabel shrink htmlFor="email"> Email </InputLabel>
  
           
            <TextField
                    id="email"
                    classes={{ root: globalClasses.customTextField }}
                    InputProps={{ disableUnderline: true }}
                    name="email"
                    margin="normal"
                    placeholder="Enter Email"
                    type="email"
                    fullWidth
                    value={onContactAdd.values.email}
                    onChange={onContactAdd.handleChange} 
                    error={onContactAdd.touched.email && Boolean(onContactAdd.errors.email)}
                    helperText={onContactAdd.touched.email && onContactAdd.errors.email}
   
                  /> 
           
          </Grid>
  
          <Grid item xs={12} sm={6} md={6} lg={6}>
          <InputLabel shrink htmlFor="phoneNumber"> Mobile </InputLabel> 
            
  
  <PhoneInput
      country={"in"}
      id="mobile"
      enableSearch={true}
      value={phoneNumber}
      className={globalClasses.customTextField}
       
    onChange={(val, allDetails) => {
      handleChange(val, allDetails);
      setIsValid(parseMobile('+'+phoneNumber)?.isValid()); 
  }}
  onBlur={(e) => {  
    setIsValid(parseMobile('+'+phoneNumber)?.isValid()); 
   }}
   margin="normal"
   name="mobile"
   variant="outlined"
   disabled={true}
    />
  <Typography variant="inherit" color="error">{ phoneNumber === ""?  onContactAdd.touched.mobile && onContactAdd.errors.mobile  :isValid !== true? "Invalid phone number format":""}</Typography>
  
          </Grid>


          <Grid item xs={12} sm={6} md={6} lg={6}>
            <InputLabel shrink htmlFor="client_name"> Client Name </InputLabel>
  
           
            <TextField
                    id="client_name"
                    classes={{ root: globalClasses.customTextField }}
                    InputProps={{ disableUnderline: true }}
                    name="client_name"
                    margin="normal"
                    placeholder="Enter Client name"
                    type="text"
                    fullWidth
                    value={onContactAdd.values.client_name}
                    onChange={onContactAdd.handleChange} 
                    error={onContactAdd.touched.client_name && Boolean(onContactAdd.errors.client_name)}
                    helperText={onContactAdd.touched.client_name && onContactAdd.errors.client_name}
   
                  /> 
           
          </Grid>

  
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          spacing={2}
          className={globalClasses.drawerFooter}
        >
       
  
          <Button
            variant="contained"
            color="primary"
            size="small"
             type="submit" 
             disabled={isValid !== true? true: onContactAdd.isSubmitting}
          >
            Save
          </Button>
  
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={toggleDrawer(anchor, false)}
          >
           
            Close
          </Button>
        </Grid>
      </CardActions>
    </form>
    </Card>
        </List>
        </Box>
);
 
  return (
    <>
      <Grid container direction="row" spacing={2} className={classes.heading}>
        <Grid item xs={9} sm={7} md={8} lg={6}>
          
          <PageTitle title="Search" />
        </Grid>

        <Grid item xs={3} sm={5} md={4} lg={6} className={classes.drawerClose}>
          
        </Grid>
      </Grid>


      <form
        ref={filterRef}
        onSubmit={(e) => {
          e.preventDefault();
          getFilterData();
        }}
      >
        <Grid container spacing={2} className={globalClasses.filterGap}>
         
        

        <Autocomplete  underlineShow={false}  options={tagData} 
                                getOptionLabel={(option) =>
  
                                  option?.name !==undefined? 
                                  user?.role_code ==="ADMIN"? `${ option?.name + " ("+ option?.createdby + ")" }`
                                  : option?.name : ''
                                }
                                
                                  value={ tagValue }
                                  onChange={(e, value) => {  
                                  setTagValue(value); 
                                  }
                                } 
                                renderInput={(params) => (
                                  <TextField 
                                    {...params}
                                    name="tag_name" 
                                    fullWidth
                                    label="Select Tag" 
                                    classes={{ root: globalClasses.filterFullWidth }}
                                    InputLabelProps={{ shrink: true }} 
                                    type="text"
                                    
                                  />
                                )}
                              />

          <div className={globalClasses.buttons}>
            <Button
              variant="contained"
              size="small"
              color="primary" 
              type="submit"
            >
              Search
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => resetForm()}
            >
              Reset
            </Button>
          </div>
        </Grid>
      </form>

      

      <Grid container spacing={2}>
        <Grid item xs={12}>
        <MUIDataTable
            title={""}
            options={{
              textLabels: {
                body: {
                  noMatch: 'Oops! Matching record could not be found',
                }
              },
              pagination: false,
              sort: false,
              selectableRows: "none",
              search: false,
              filter: false,
              print: false,
              download: false,
            customToolbar: () => <HeaderElements />,
              responsive: mobileQuery===true? 'vertical' : 'standard',
            }}
            columns={[
              {
                name: "S.No",
              },
              {
                name: "Contact",
              },
             
              {
                name: "Tags",
              },
              {
                name: "Created By",
              },
              {
                name: "Posted Date",
              },
            ]}
            data={searchData?.map((item, index) => {
              return [
              currerntPage !== 0 ? 10 * currerntPage - 10 + index + 1   : index + 1 ,  
               
                <Grid className={globalClasses.gap10}> {item.name !== null ? item.name : item.phone_number}

                {item.contact_id === null? 
                <Tooltip title="Add to Contact" placement="bottom"   aria-label="contact">  
                 <IconButton style={{ padding: "0px"}}>  
                 <FaUserPlus className={globalClasses.toolIcon} onClick={(e) =>{
                   setIsValid(true);
                   const phoneNumber = parsePhoneNumber("+"+item.phone_number)
                    if (phoneNumber) { 
                     setCountryCode(phoneNumber.countryCallingCode);
                    setPhoneNumber(phoneNumber.countryCallingCode+phoneNumber.nationalNumber);
                
                    onContactAdd.resetForm();
                    onContactAdd.setValues({ "name": '', "email": '', "mobile": phoneNumber.countryCallingCode+phoneNumber.nationalNumber });
                    
                    setState({ ...state, right: true });
                
                  }
                 }}
                /> 
                </IconButton>
              </Tooltip> : ""}</Grid>,
                <Button variant="contained" size="small" style={{ pointerEvents: "none"}} className={ classes.green  }> 
                  {item?.tag_name}  </Button>,
          
          item?.createdby,

          item?.tag_created? moment(item?.tag_created).tz(moment.tz.guess()).format("DD-MM-YYYY") :"",
              ];
            })}
          />

          <Grid container spacing={2} className={globalClasses.pagination}>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={count}
              rowsPerPage={10}
              page={page}
              onChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
      </Grid>


      <SwipeableDrawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
            classes={{ paper: globalClasses.drawer }}
            disableSwipeToOpen={false}
          >
 
            {   list("right")  }
          </SwipeableDrawer>

      

      <Backdrop className={globalClasses.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

