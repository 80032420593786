import { Page, Text, View, Document, StyleSheet, PDFViewer, pdf } from "@react-pdf/renderer";
import React, {  useEffect } from "react";

//  import Logo from '../../../images/google.svg';
import { saveAs } from 'file-saver';
 
const PDF = (props) => {
    var moment = require('moment-timezone');

  const styles = StyleSheet.create({

    page:{
    fontSize: "11px",
    color: "rgba(0, 0, 0, 0.76)", 
    padding: 20,
    lineHeight: "1.5",
    },
    card: {
      gap: "30px",
      flexDirection: "row",
      backgroundColor: "white"
    },
    section: { 
      flexGrow: 1,
      lineBreak: "anywhere",
    },
    headerFont:{  
      color: "rgba(0, 0, 0, 0.99)", 
      fontSize: "13px",   
    },
    headerFonts:{  
      paddingTop: "5px",
      color: "rgba(0, 0, 0, 0.99)",  
    },
    spageFont:{  
      fontSize: "8px",
    }, 
   divider:{
    borderBottom: "2px solid #e0e0e0",
    },
    table: {
      display: 'table',
      width: '100%',
      border: "1px solid #e0e0e0",
    },
    tableRow: {
       
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#ccc',
      borderBottomStyle: 'solid',
     
    },
    tableCell: { 
      margin: 'auto',
      fontSize: 10,
      padding: 5,
      width: '100%', 
    },
    tableCellRight: { 
      margin: 'auto',
      fontSize: 10,
      padding: 5,
      width: '100%',  
      textAlign: "right"
    },
  });


  
  const MyDocument = (props) => (
    <Document >
    <Page size="A4" style={styles.page} > 
    <View style={styles.card}> 
      <View style={styles.section}>
        <Text>infoapto </Text>
        {/* <Text>Instance number: </Text> 
        <Text style={{  color: "rgba(0, 0, 0, 0.99)", }}>{props.transactionView?.data?.instance_id}</Text> */}
        <Text>  </Text>
        <Text>Bill to Address: {props.transactionView?.data?.companyName}</Text>
        {/* <Text>ATTN: Vishal</Text> */}
        <Text style={{ width: "30%"}}>{props.transactionView?.data?.address}</Text>
      </View>
      <View style={[styles.section, { padding: '10px', lineHeight: "1.8"}]} >
        <Text  style={styles.headerFont}>infoapto Web Services Statement </Text>
        <Text   style={[styles.spageFont, { borderBottom: '2px solid #e0e0e0' }]}>Email or talk to us about your infoapto account or bill, visit wa.infoapto.com </Text>
        <Text  style={[styles.headerFonts, styles.divider,  ]}>Invoice Summary </Text>
        <Text style={[styles.spageFont,   { paddingTop:"5px"} ]}> Invoice Number:                                                                                    {props.transactionView?.data?.invoice_number}</Text>
        <Text style={[styles.spageFont,  styles.divider,]}>       Invoice Date:                                                                                    {moment(props.transactionView?.data?.created).tz(moment.tz.guess()).format('DD-MM-YYYY H:mm')} </Text>
        <Text  style={[styles.headerFonts, styles.divider, ]}> <Text>TOTAL AMOUNT DUE BY {moment(props.transactionView?.data?.created).tz(moment.tz.guess()).format('MMM Do YYYY')} </Text>                 <Text style={styles.tableCellRight}> INR {props.transactionView?.data?.total_amount}</Text> </Text>
      </View>
      </View>
      <View > <Text> </Text> </View>
      <View >
      {/* <Text  style={styles.headerFont}>This Account Summary is for the billing period July 1 - July 31 , 2023</Text> */}
        <Text>Greetings from infoapto (formerly known as infoapto). We are writing to provide
you with an account summary of your use of infoapto services. Additional information about your bill, individual service charge details, and your account
history are available on the Account Activity Page. </Text>
      </View>
      <View > <Text> </Text> </View>
      
     

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, { backgroundColor: "#cbe8ff",   fontSize: "14px", fontWeight: "900"  }]}>Summary</Text>
            <Text > </Text> 
          </View>
          
            <View  style={styles.tableRow}>  <Text style={[styles.tableCell]} >infoapto Service Charges  </Text>  <Text style={styles.tableCellRight}> INR {props.transactionView?.data?.basic_amount}</Text>  </View>
          
            <View  style={styles.tableRow}>  <Text style={styles.tableCell}>CGST</Text>  <Text style={styles.tableCellRight}>  INR {props.transactionView?.data?.cgst}</Text>   </View>
            <View  style={styles.tableRow}>   <Text style={styles.tableCell}>SGST</Text>  <Text style={styles.tableCellRight}> INR {props.transactionView?.data?.sgst}</Text> </View>
            {/* <View  style={styles.tableRow}>  <Text style={styles.tableCell}>Charges</Text>  <Text style={styles.tableCellRight}>  INR 0.00</Text>   </View>
            <View  style={styles.tableRow}>   <Text style={styles.tableCell}>Credits</Text>  <Text style={styles.tableCellRight}> INR 0.00</Text> </View> */}
             {/* <View  style={styles.tableRow}>   <Text style={styles.tableCell}>Total for this statement in USD</Text>  <Text style={styles.tableCellRight}> $15.99</Text> </View> */}
            {/* <View  style={styles.tableRow}>  <Text style={styles.tableCell}>Total for this statement (1 USD = 82.25630000 INR ) 1 </Text>  <Text style={styles.tableCellRight}> INR 1,315.28</Text> </View> */}
            <View  style={[styles.tableRow,  { backgroundColor: "#ddd"  }]}>   <Text style={styles.tableCell}>INR Total</Text>  <Text style={styles.tableCellRight}> INR {props.transactionView?.data?.total_amount}</Text> </View>

         
        </View>
         
      <View > <Text> </Text> </View>

      
      {/* <View style={styles.card}> 
      <View style={[styles.section, ]}>
        <Text style={{ fontSize: "7px", }}>† Usage and recurring charges for this statement period will be charged on</Text>
        <Text style={{ fontSize: "7px", }}>your next billing date. The amount of your actual charges for this statement</Text>
        <Text style={{ fontSize: "7px", }}>period may differ from the charges shown on this page. The charges shown</Text>
        <Text style={{ fontSize: "7px", }}>on this page do not include any additional usage charges accrued during this</Text>
        <Text style={{ fontSize: "7px", }}>statement period after the date you are viewing this page. Also, one-time fees</Text>
        <Text style={{ fontSize: "7px", }}>and subscription charges are assessed separately, on the date that they occur.</Text>
         <Text style={{ fontSize: "7px", }}>All charges and prices are in US Dollars</Text>
      </View>
      <View style={[styles.section, { alignSelf: "flex-end"}]}>
        <Text  style={{ fontSize: "7px",  color: "rgba(0, 0, 0, 0.99)",  }}> Please note: this is not a GST Invoice. To view your GST invoice, please go to the</Text>
        <Text  style={{ fontSize: "7px",  color: "rgba(0, 0, 0, 0.99)", }}>Bills page in Billing and Management Console</Text>
      </View>
      </View> */}
       
    </Page>
  </Document>
  );
  
  const generatePdfDocument = async (documentData, fileName) => {
    const blob = await pdf((
      <MyDocument transactionView={documentData} handleClose={props.handleClose}  />  
    )).toBlob();
    saveAs(blob, props.transactionView?.data?.invoice_number+".pdf");
};

useEffect(() => { 
   
  if(props.transactionView?.data?.invoice_number !== undefined){
    generatePdfDocument(props.transactionView, props.transactionView?.data?.invoice_number+".pdf")
  }
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [props.transactionView?.data?.invoice_number]);
  return (
    <>
   
    <PDFViewer  >
    <MyDocument transactionView={props.transactionView} handleClose={props.handleClose}  />  
  </PDFViewer>
  

    
 


   </>
  );
};
export default PDF;
