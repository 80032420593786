import { makeStyles } from "@material-ui/styles";

const drawerWidth = 187;

export default makeStyles(theme => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    color: "white !important", //new Changes
    backgroundColor: "#3f71d7 !important", //new Changes 2196f3
  //  borderRight: "1px solid #fff !important",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    color: "white !important", //new Changes
    backgroundColor: "#3f71d7 !important", //new Changes
   // borderRight: "1px solid #fff !important",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 14,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    borderRight: "2px solid #3f71d7",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "#fff"

  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "white",

  },
  /* sidebarList: {
    marginTop: theme.spacing(6),
  }, */
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: 18,
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    "& button":{
      color: "#fff"
    }
  },
}));
