import { api } from "./api";
 
 export const sendTemplateMessage = async (data, value) => {
  
  var url= "instance/sendTemplateMessage"; 
  
   api.defaults.headers.common['Authorization'] = value.token;
    api.defaults.headers.common['Content-Type']= 'application/json'

  const mobile = data.mobile_number.replace(/\s/g, '').split(','); 
  const mobile_number = mobile?.map((value) => `${value}`); 

    const response = await api.post(url, {
      template_id: value.template_id,
      template_name: data.template_name,
      parameters: data.parameters,
      mobile_number: mobile_number.join(','), 
      language_code: data.language_code,
      message: value.message,  
      instance_id: value.instance_id,  
      headerType: data.headerType,
      fileLink: data.header
     });
 
    return response;
  };
   
 
  export const sendTextMessage = async (data, value) => {
    
    var url= "instance/sendTextMessage"; 
 

    const mobile = data.mobile_number.replace(/\s/g, '').split(',');
     const mobile_number = mobile?.map((value) => `${value}`);
     api.defaults.headers.common['Authorization'] = value.token;
     api.defaults.headers.common['Content-Type']= 'application/json'
    
    const response = await api.post(url, { 
      mobile_number: mobile_number.join(','),
      message: data.message,  
      instance_id: value.instance_id,   
     });
    return response;
  };
   
  
  export const sendAudioMessage = async (data) => {
    var url= "instance/sendAudioMessage"; 
    
    const mobile = data.mobile_number.replace(/\s/g, '').split(','); 
    const mobile_number = mobile?.map((value) => `${value}`);
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post(url, { 
      mobile_number: mobile_number.join(','),
      file_url: data.file_url,  
      file_path: data.file_path,  
      instance_id: data.instance_id,   
     });
    return response;
  };
  

  export const sendDocumentMessage = async (data) => {

    var url= "instance/sendDocumentMessage"; 
 

    const mobile = data.mobile_number.replace(/\s/g, '').split(','); 
    const mobile_number = mobile?.map((value) => `${value}`); 
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post(url, { 
      mobile_number: mobile_number.join(','),
      file_url: data.file_url,  
      file_path: data.file_path,  
      instance_id: data.instance_id,   
     });
    return response;
  };
   
  
  export const sendImageMessage = async (data) => {

    var url= "instance/sendImageMessage"; 
 

    const mobile = data.mobile_number.replace(/\s/g, '').split(','); 
    const mobile_number = mobile?.map((value) => `${value}`);
      api.defaults.headers.common['Authorization'] = data.token;
      api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post(url, { 
      mobile_number: mobile_number.join(','),
      file_url: data.file_url,  
      file_path: data.file_path,  
      instance_id: data.instance_id,   
     });
    return response;
  };

  export const sendVideoMessage = async (data) => {

    var url= "instance/sendVideoMessage"; 
   

    const mobile = data.mobile_number.replace(/\s/g, '').split(','); 
    const mobile_number = mobile?.map((value) => `${value}`);
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post(url, { 
      mobile_number: mobile_number.join(','),
      file_url: data.file_url,  
      file_path: data.file_path,  
      instance_id: data.instance_id,   
     });
    return response;
  };

  export const sendContactMessage = async (data, value) => {

    var url= "instance/sendContactMessage"; 
   

    const mobile = data.mobile_number.replace(/\s/g, '').split(',');
     const mobile_number = mobile?.map((value) => `${value}`);
     api.defaults.headers.common['Authorization'] = value.token;
     api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post(url, { 
      mobile_number: mobile_number.join(','),
      contact_number: data.contact_number, 
      contact_name: data.first_name +" "+data.last_name,  
      instance_id: value.instance_id,   
     });
    return response;
  };


  export const sendLocationMessage = async (data, value) => {
    var url= "instance/sendLocationMessage"; 
  
    const mobile = data.mobile_number.replace(/\s/g, '').split(','); 
    const mobile_number = mobile?.map((value) => `${value}`);
    api.defaults.headers.common['Authorization'] = value.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post(url, { 
      mobile_number: mobile_number.join(','),
      latitude: data.latitude, 
      longitude: data.longitude,  
      address: data.address,  
      instance_id: value.instance_id,   
     });
    return response;
  };
 