import React, { useState, useEffect } from 'react'
import { Grid, Typography, Switch, Backdrop,
    CircularProgress,   } from '@material-ui/core';
import useStyles from "./style";
//import globalStyles from "../../themes/style.js"; 
import { changeAutoReplyStatus, viewAuthentication  } from '../../services/instance.api'; 
 
import Notification from "../../components/Notification/Notification"; 

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function Settings(props) {
   const classes = useStyles(); 
//   const globalClasses = globalStyles();

  const [loader, setLoader] = useState(false); 
  const token= localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem("user"));
  
  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress, 
          className: classes.notification,
        },
    );
  } 

  const [replyValue, setReplyValue] = useState(false);

  function handleStatus(value) { 
    setLoader(true); 
    changeAutoReplyStatus({instance_id: props.instanceId?.id, token: token}).then((response) => {    
      if (response.data.status === true) {   
            handleNotificationCall("success", response.data.message);
            handleInstanceId();
          }  else{
            handleNotificationCall("error", response.data.message);
          }

          setReplyValue(value); 
          setLoader(false); 
        
          
        })
          .catch(function (error) {
           console.log(error);
         }) 

}

useEffect(() => { 
 
  setReplyValue(parseInt(props.instanceId?.is_enable_autoreply) === 1? true : false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   function handleInstanceId() {  
    viewAuthentication({userId: user?.userId, token: token}).then((response) => {   
     
        if (response.data.status === true) {   
            props.setInstanceId(JSON.parse(JSON.stringify(response?.data?.data))); 
          
      
           if(parseInt(response?.data?.data?.istrash) === 1){
            
            props.setInstanceId();

           }
           
         }  
      }) 
      .catch(function (error) {
       console.log(error);
     })

     
   }

  return (
    <>
    <Grid
    container
    direction="row"
    spacing={2}
   
  >
  <Grid item xs={12}   >

  <Grid item xs={6}    > 
   

   <Typography> Enable Quick Reply  <Switch
                checked={replyValue}
                onChange={(e) => {
                   handleStatus(e.target.checked);
                }}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }} /> </Typography>

          </Grid>

          <Grid xs={6}    >

          </Grid>
            </Grid>

            </Grid>


<Backdrop className={classes.backdrop} open={loader}>
<CircularProgress color="inherit" />
</Backdrop>
</>
  )
}

export default Settings